import { memo, useEffect, useState } from "react";
import styles from "./entryPoint.module.scss";
import { AdditionalCostsPageProps } from "../../../interfaces/pages";
import { CircularProgress, Divider, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import TextFieldShared from "../../../components/TextField/TextField";
import { useToast } from "../../../helpers/hooks";
import ButtonShared from "../../../components/Button/Button";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

const AdditionalCosts = ({
    additionalCosts,
    getAdditionalCosts,
    cleanGetAdditionalCosts,
    updateAdditionalCosts,
    cleanUpdateAdditionalCosts
}: AdditionalCostsPageProps) => {
    const toast = useToast();
    const [openSection, setOpenSection] = useState<any>({
        financing: false,
        cash: false,
        publication: false
    });
    const [financing, setFinancing] = useState<any>({
        legalTax: {
            value: "",
            error: ""
        },
        civilRegistration: {
            value: "",
            error: ""
        },
        legalManager: {
            value: "",
            error: ""
        },
        finalCAV: {
            value: "",
            error: ""
        },
        transactionCost: {
            value: "",
            error: ""
        },
        purchaseCommission: {
            value: "",
            error: ""
        },
        platformUse: {
            value: "",
            error: ""
        },
        vehicleTransfer: {
            value: "",
            error: ""
        }
	});
    const [cash, setCash] = useState<any>({
        legalTax: {
			value: "",
            error: ""
		},
        legalRegistration: {
			value: "",
            error: ""
		},
        legalManager: {
			value: "",
            error: ""
		},
		finalCAV: {
			value: "",
            error: ""
		},
        transactionCost: {
			value: "",
            error: ""
		},
        purchaseCommission: {
			value: "",
            error: ""
		},
        platformUse: {
			value: "",
            error: ""
		},
        vehicleTransfer: {
			value: "",
            error: ""
		}
	});
    const [publication, setPublication] = useState<any>({
        id: "",
        transactionCost: {
			value: "",
            error: ""
		},
		CAVvalidation: {
			value: "",
            error: ""
		},
        finesCertification: {
			value: "",
            error: ""
		},
        sellCommission: {
			value: "",
            error: ""
		},
        platformUse: {
			value: "",
            error: ""
		},
        inspection: {
			value: "",
            error: ""
		}
	});

    const onSaveFinancing = () => {
        const data = financing && Object.values(financing).map((item: any) => {
            const res = additionalCosts?.data?.financing.find((financingItem: any) => financingItem._id === item?.id)
            if(res) return {...res, value: item?.value};
        }).filter(res => res !== undefined);
        updateAdditionalCosts(data);
    }

    const onSaveCash = () => {
        const data = cash && Object.values(cash).map((item: any) => {
            const res = additionalCosts?.data?.cash.find((cashItem: any) => cashItem._id === item?.id)
            if(res) return {...res, value: item?.value};
        }).filter(res => res !== undefined);
        updateAdditionalCosts(data);
    }

    const onSavePublication = () => {
        const data = publication && Object.values(publication).map((item: any) => {
            const res = additionalCosts?.data?.publication.find((publicationItem: any) => publicationItem._id === item?.id)
            if(res) return {...res, value: item?.value};
        }).filter(res => res !== undefined);
        updateAdditionalCosts(data);
    }

    useEffect(() => {
        if(additionalCosts?.update?.fetching === "failure"){
            toast("Ha ocurrido un error al actualizar los costos adicionales");
            cleanUpdateAdditionalCosts();
        }
        if(additionalCosts?.update?.fetching === "success"){
            toast("Costos adicionales actualizados correctamente");
            cleanUpdateAdditionalCosts();
        }
    }, [additionalCosts?.update?.fetching]);
    
    useEffect(() => {
        if(additionalCosts?.fetching === "failure"){
            toast("Ha ocurrido un error al mostrar los costos adicionales");
            cleanGetAdditionalCosts();
        }
        if(additionalCosts?.fetching === "success"){
            cleanGetAdditionalCosts();
        }
    }, [additionalCosts?.fetching]);

    useEffect(() => {
        if(additionalCosts?.data?.financing){
            const financing: any = additionalCosts?.data?.financing;
            const transfer_tax_collection = financing.find((item: any) => item.code === "transfer_tax_collection");
            const civ_reg_registration_collection = financing.find((item: any) => item.code === "civ_reg_registration_collection");
            const legal_manager_expense_collection = financing.find((item: any) => item.code === "legal_manager_expense_collection");
            const final_cav_collection = financing.find((item: any) => item.code === "final_cav_collection");
            const transaction_cost = financing.find((item: any) => item.code === "transaction_cost");
            const purchase_commission = financing.find((item: any) => item.code === "purchase_commission");
            const platform_use = financing.find((item: any) => item.code === "platform_use");
            const vehicle_transfer = financing.find((item: any) => item.code === "vehicle_transfer");
            setFinancing({
                legalTax: {
                    value: transfer_tax_collection?.value ?? "",
                    id: transfer_tax_collection?._id,
                    error: ""
                },
                civilRegistration: {
                    value: civ_reg_registration_collection?.value ?? "",
                    id: civ_reg_registration_collection?._id,
                    error: ""
                },
                legalManager: {
                    value: legal_manager_expense_collection?.value ?? "",
                    id: legal_manager_expense_collection?._id,
                    error: ""
                },
                finalCAV: {
                    value: final_cav_collection?.value ?? "",
                    id: final_cav_collection?._id,
                    error: ""
                },
                transactionCost: {
                    value: transaction_cost?.value ?? "",
                    id: transaction_cost?._id,
                    error: ""
                },
                purchaseCommission: {
                    value: purchase_commission?.value ?? "",
                    id: purchase_commission?._id,
                    error: ""
                },
                platformUse: {
                    value: platform_use?.value ?? "",
                    id: platform_use?._id,
                    error: ""
                },
                vehicleTransfer: {
                    value: vehicle_transfer?.value ?? "",
                    id: vehicle_transfer?._id,
                    error: ""
                }
            });
        }
    }, [additionalCosts?.data?.financing]);

    useEffect(() => {
        if(additionalCosts?.data?.cash){
            const cash: any = additionalCosts?.data?.cash;
            const transfer_tax_collection = cash.find((item: any) => item.code === "transfer_tax_collection");
            const transfer_legal_registration = cash.find((item: any) => item.code === "transfer_legal_registration");
            const legal_manager_expense_collection = cash.find((item: any) => item.code === "legal_manager_expense_collection");
            const final_cav_collection = cash.find((item: any) => item.code === "final_cav_collection");
            const transaction_cost = cash.find((item: any) => item.code === "transaction_cost");
            const purchase_commission = cash.find((item: any) => item.code === "purchase_commission");
            const platform_use = cash.find((item: any) => item.code === "platform_use");
            const vehicle_transfer = cash.find((item: any) => item.code === "vehicle_transfer");
            setCash({
                legalTax: {
                    value: transfer_tax_collection?.value ?? "",
                    id: transfer_tax_collection?._id,
                    error: ""
                },
                legalRegistration: {
                    value: transfer_legal_registration?.value ?? "",
                    id: transfer_legal_registration?._id,
                    error: ""
                },
                legalManager: {
                    value: legal_manager_expense_collection?.value ?? "",
                    id: legal_manager_expense_collection?._id,
                    error: ""
                },
                finalCAV: {
                    value: final_cav_collection?.value ?? "",
                    id: final_cav_collection?._id,
                    error: ""
                },
                transactionCost: {
                    value: transaction_cost?.value ?? "",
                    id: transaction_cost?._id,
                    error: ""
                },
                purchaseCommission: {
                    value: purchase_commission?.value ?? "",
                    id: purchase_commission?._id,
                    error: ""
                },
                platformUse: {
                    value: platform_use?.value ?? "",
                    id: platform_use?._id,
                    error: ""
                },
                vehicleTransfer: {
                    value: vehicle_transfer?.value ?? "",
                    id: vehicle_transfer?._id,
                    error: ""
                }
            });
        }
    }, [additionalCosts?.data?.cash]);
    
    useEffect(() => {
        if(additionalCosts?.data?.publication){
            const publication: any = additionalCosts?.data?.publication;
            const seller_transaction_cost = publication.find((item: any) => item.code === "seller_transaction_cost");
            const cav_validation = publication.find((item: any) => item.code === "cav_validation");
            const fines_certificate = publication.find((item: any) => item.code === "fines_certificate");
            const sales_commission = publication.find((item: any) => item.code === "sales_commission");
            const platform_use = publication.find((item: any) => item.code === "platform_use");
            const inspection = publication.find((item: any) => item.code === "inspection");
            setPublication({
                transactionCost: {
                    value: seller_transaction_cost?.value ?? "",
                    id: seller_transaction_cost?._id,
                    error: ""
                },
                CAVvalidation: {
                    value: cav_validation?.value ?? "",
                    id: cav_validation?._id,
                    error: ""
                },
                finesCertification: {
                    value: fines_certificate?.value ?? "",
                    id: fines_certificate?._id,
                    error: ""
                },
                sellCommission: {
                    value: sales_commission?.value ?? "",
                    id: sales_commission?._id,
                    error: ""
                },
                platformUse: {
                    value: platform_use?.value ?? "",
                    id: platform_use?._id,
                    error: ""
                },
                inspection: {
                    value: inspection?.value ?? "",
                    id: inspection?._id,
                    error: ""
                }
            });
        }
    }, [additionalCosts?.data?.publication]);

    useEffect(() => {
        getAdditionalCosts("financing");
        getAdditionalCosts("cash");
        getAdditionalCosts("publication");
    }, []);

    return (
        <div className={styles.additionalCosts}>
            <div className={`${styles.cardContainer} ${styles.financing} ${openSection?.financing && styles.open}`}>
				<div className={styles.header} onClick={() => setOpenSection({financing: !openSection.financing, cash: false, publication: false})}>
					<p>
						<span className={styles.title}>Compra con crédito</span>
						{/* <span className={styles.description}>
							El ingreso de tus datos de cuenta bancaria es obligatorio. Recuerda que debes ser el titular de esta cuenta.
						</span> */}
					</p>
					{
						additionalCosts?.fetching === "loading" &&
						<CircularProgress 
                            size={styles.loader}
                            classes={{
                                colorPrimary: styles.loader
                            }}
                        />
					}
					<IconButton className={styles.arrow} onClick={() => setOpenSection({financing: !openSection.financing, cash: false, publication: false})}>
						<ArrowBackIosNewIcon className={styles.icon} />
					</IconButton>
				</div>
				<div className={styles.content}>
                    <p className={styles.title}>Transferencia / inspección legal:</p>
                    <div className={styles.grid}>
                        <TextFieldShared
                            type="number"
                            label="Cobro impuesto transferencia"
                            name="financingLegalTax"
                            onChange={(value: any) => setFinancing({...financing, legalTax: {...financing.legalTax, value: parseFloat(value)}})}
                            value={financing?.legalTax?.value}
                            error={financing?.legalTax?.error}
                            onEnter={onSaveFinancing}
                            icon="%"
                            loading={additionalCosts?.fetching === "loading"}
                        />
                        <TextFieldShared
                            type="number"
                            label="Cobro inscripción reg. civil"
                            name="financingCivilRegistration"
                            onChange={(value: any) => setFinancing({...financing, civilRegistration: {...financing.civilRegistration, value: parseFloat(value)}})}
                            value={financing?.civilRegistration?.value}
                            error={financing?.civilRegistration?.error}
                            onEnter={onSaveFinancing}
                            icon="$"
                            loading={additionalCosts?.fetching === "loading"}
                        />
                        <TextFieldShared
                            type="number"
                            label="Cobro gasto gestor legal (honorario)"
                            name="financingLegalManager"
                            onChange={(value: any) => setFinancing({...financing, legalManager: {...financing.legalManager, value: parseFloat(value)}})}
                            value={financing?.legalManager?.value}
                            error={financing?.legalManager?.error}
                            onEnter={onSaveFinancing}
                            icon="$"
                            loading={additionalCosts?.fetching === "loading"}
                        />
                        <TextFieldShared
                            type="number"
                            label="Cobro CAV final"
                            name="financingFinalCAV"
                            onChange={(value: any) => setFinancing({...financing, finalCAV: {...financing.finalCAV, value: parseFloat(value)}})}
                            value={financing?.finalCAV?.value}
                            error={financing?.finalCAV?.error}
                            onEnter={onSaveFinancing}
                            icon="$"
                            loading={additionalCosts?.fetching === "loading"}
                        />
                    </div>
                    <Divider />
                    <p className={styles.title}>Costo de transacción:</p>
                    <div className={styles.grid}>
                        <TextFieldShared
                            type="number"
                            label="Comisión de compra"
                            name="financingPurchaseCommission"
                            onChange={(value: any) => setFinancing({...financing, purchaseCommission: {...financing.purchaseCommission, value: parseFloat(value)}})}
                            value={financing?.purchaseCommission?.value}
                            error={financing?.purchaseCommission?.error}
                            onEnter={onSaveFinancing}
                            icon="$"
                            loading={additionalCosts?.fetching === "loading"}
                        />
                        <TextFieldShared
                            type="number"
                            label="Uso de la plataforma"
                            name="financingPlatformUse"
                            onChange={(value: any) => setFinancing({...financing, platformUse: {...financing.platformUse, value: parseFloat(value)}})}
                            value={financing?.platformUse?.value}
                            error={financing?.platformUse?.error}
                            onEnter={onSaveFinancing}
                            icon="$"
                            loading={additionalCosts?.fetching === "loading"}
                        />
                    </div>
                    <p className={styles.title}>Traslado vehículo:</p>
                    <div className={styles.grid}>
                        <TextFieldShared
                            type="number"
                            label="Traslado vehículo"
                            name="financingVehícleTransfer"
                            onChange={(value: any) => setFinancing({...financing, vehicleTransfer: {...financing.vehicleTransfer, value: parseFloat(value)}})}
                            value={financing?.vehicleTransfer?.value}
                            error={financing?.vehicleTransfer?.error}
                            onEnter={onSaveFinancing}
                            icon="$"
                            loading={additionalCosts?.fetching === "loading"}
                        />
                    </div>
                    <Divider />
                    <ButtonShared
                        onPress={onSaveFinancing}
                        title="Guardar"
                        icon={<SaveOutlinedIcon className={styles.iconButton} />}
                        primary
                        disabled={additionalCosts.fetching === "loading"}
                        loading={additionalCosts.update.fetching === "loading"}
                        className={styles.saveButton}
                    />
                </div>
			</div>
            <div className={`${styles.cardContainer} ${styles.cash} ${openSection?.cash && styles.open}`}>
				<div className={styles.header} onClick={() => setOpenSection({financing: false, cash: !openSection.cash, publication: false})}>
					<p>
						<span className={styles.title}>Compra con contado</span>
						{/* <span className={styles.description}>
							El ingreso de tus datos de cuenta bancaria es obligatorio. Recuerda que debes ser el titular de esta cuenta.
						</span> */}
					</p>
					{
						additionalCosts?.fetching === "loading" &&
						<CircularProgress 
                            size={styles.loader}
                            classes={{
                                colorPrimary: styles.loader
                            }}
                        />
					}
					<IconButton className={styles.arrow} onClick={() => setOpenSection({financing: false, cash: !openSection.cash, publication: false})}>
						<ArrowBackIosNewIcon className={styles.icon} />
					</IconButton>
				</div>
				<div className={styles.content}>
                    <p className={styles.title}>Transferencia / inspección legal:</p>
                    <div className={styles.grid}>
                        <TextFieldShared
                            type="number"
                            label="Cobro impuesto transferencia"
                            name="cashLegalTax"
                            onChange={(value: any) => setCash({...cash, legalTax: {...cash.legalTax, value: parseFloat(value)}})}
                            value={cash?.legalTax?.value}
                            error={cash?.legalTax?.error}
                            onEnter={onSaveCash}
                            icon="%"
                            loading={additionalCosts?.fetching === "loading"}
                        />
                        <TextFieldShared
                            type="number"
                            label="Cobro gasto gestor legal (honorario)"
                            name="cashLegalRegistration"
                            onChange={(value: any) => setCash({...cash, legalRegistration: {...cash.legalRegistration, value: parseFloat(value)}})}
                            value={cash?.legalRegistration?.value}
                            error={cash?.legalRegistration?.error}
                            onEnter={onSaveCash}
                            icon="$"
                            loading={additionalCosts?.fetching === "loading"}
                        />
                        <TextFieldShared
                            type="number"
                            label="Cobro CAV final"
                            name="cashFinalCAV"
                            onChange={(value: any) => setCash({...cash, finalCAV: {...cash.finalCAV, value: parseFloat(value)}})}
                            value={cash?.finalCAV?.value}
                            error={cash?.finalCAV?.error}
                            onEnter={onSaveCash}
                            icon="$"
                            loading={additionalCosts?.fetching === "loading"}
                        />
                        <TextFieldShared
                            type="number"
                            label="Costo transacción"
                            name="cashTransactionCost"
                            onChange={(value: any) => setCash({...cash, transactionCost: {...cash.transactionCost, value: parseFloat(value)}})}
                            value={cash?.transactionCost?.value}
                            error={cash?.transactionCost?.error}
                            onEnter={onSaveCash}
                            icon="$"
                            loading={additionalCosts?.fetching === "loading"}
                        />
                    </div>
                    <Divider />
                    <p className={styles.title}>Costo de transacción:</p>
                    <div className={styles.grid}>
                        <TextFieldShared
                            type="number"
                            label="Comisión de compra"
                            name="cashPurchaseCommission"
                            onChange={(value: any) => setCash({...cash, purchaseCommission: {...cash.purchaseCommission, value: parseFloat(value)}})}
                            value={cash?.purchaseCommission?.value}
                            error={cash?.purchaseCommission?.error}
                            onEnter={onSaveCash}
                            icon="$"
                            loading={additionalCosts?.fetching === "loading"}
                        />
                        <TextFieldShared
                            type="number"
                            label="Uso de la plataforma"
                            name="cashPlatformUse"
                            onChange={(value: any) => setCash({...cash, platformUse: {...cash.platformUse, value: parseFloat(value)}})}
                            value={cash?.platformUse?.value}
                            error={cash?.platformUse?.error}
                            onEnter={onSaveCash}
                            icon="$"
                            loading={additionalCosts?.fetching === "loading"}
                        />
                    </div>
                    <p className={styles.title}>Traslado vehículo:</p>
                    <div className={styles.grid}>
                        <TextFieldShared
                            type="number"
                            label="Traslado vehículo"
                            name="cashVehícleTransfer"
                            onChange={(value: any) => setCash({...cash, vehicleTransfer: {...cash.vehicleTransfer, value: parseFloat(value)}})}
                            value={cash?.vehicleTransfer?.value}
                            error={cash?.vehicleTransfer?.error}
                            onEnter={onSaveCash}
                            icon="$"
                            loading={additionalCosts?.fetching === "loading"}
                        />
                    </div>
                    <Divider />
                    <ButtonShared
                        onPress={onSaveCash}
                        title="Guardar"
                        icon={<SaveOutlinedIcon className={styles.iconButton} />}
                        primary
                        disabled={additionalCosts.fetching === "loading"}
                        loading={additionalCosts.update.fetching === "loading"}
                        className={styles.saveButton}
                    />
                </div>
			</div>
            <div className={`${styles.cardContainer} ${styles.publication} ${openSection?.publication && styles.open}`}>
				<div className={styles.header} onClick={() => setOpenSection({financing: false, cash: false, publication: !openSection.publication})}>
					<p>
						<span className={styles.title}>Venta</span>
						{/* <span className={styles.description}>
							El ingreso de tus datos de cuenta bancaria es obligatorio. Recuerda que debes ser el titular de esta cuenta.
						</span> */}
					</p>
					{
						additionalCosts?.fetching === "loading" &&
						<CircularProgress 
                            size={styles.loader}
                            classes={{
                                colorPrimary: styles.loader
                            }}
                        />
					}
					<IconButton className={styles.arrow} onClick={() => setOpenSection({financing: false, cash: false, publication: !openSection.publication})}>
						<ArrowBackIosNewIcon className={styles.icon} />
					</IconButton>
				</div>
				<div className={styles.content}>
                    <p className={styles.title}>Costo transaccion vendedor:</p>
                    <div className={styles.grid}>
                        <TextFieldShared
                            type="number"
                            label="CAV validación"
                            name="pubCAVvalidation"
                            onChange={(value: any) => setPublication({...publication, CAVvalidation: {...publication.CAVvalidation, value: parseFloat(value)}})}
                            value={publication?.CAVvalidation?.value}
                            error={publication?.CAVvalidation?.error}
                            onEnter={onSavePublication}
                            icon="$"
                            loading={additionalCosts?.fetching === "loading"}
                        />
                        <TextFieldShared
                            type="number"
                            label="Certificado de Multas"
                            name="pubFinesCertification"
                            onChange={(value: any) => setPublication({...publication, finesCertification: {...publication.finesCertification, value: parseFloat(value)}})}
                            value={publication?.finesCertification?.value}
                            error={publication?.finesCertification?.error}
                            onEnter={onSavePublication}
                            icon="$"
                            loading={additionalCosts?.fetching === "loading"}
                        />
                        <TextFieldShared
                            type="number"
                            label="Comisión de venta"
                            name="pubSellCommission"
                            onChange={(value: any) => setPublication({...publication, sellCommission: {...publication.sellCommission, value: parseFloat(value)}})}
                            value={publication?.sellCommission?.value}
                            error={publication?.sellCommission?.error}
                            onEnter={onSavePublication}
                            icon="$"
                            loading={additionalCosts?.fetching === "loading"}
                        />
                        <TextFieldShared
                            type="number"
                            label="Uso de la Plataforma"
                            name="pubPlatformUse"
                            onChange={(value: any) => setPublication({...publication, platformUse: {...publication.platformUse, value: parseFloat(value)}})}
                            value={publication?.platformUse?.value}
                            error={publication?.platformUse?.error}
                            onEnter={onSavePublication}
                            icon="$"
                            loading={additionalCosts?.fetching === "loading"}
                        />
                    </div>
                    <Divider />
                    <p className={styles.title}>Inspección:</p>
                    <div className={styles.grid}>
                        <TextFieldShared
                            type="number"
                            label="Inspección"
                            name="pubInspection"
                            onChange={(value: any) => setPublication({...publication, inspection: {...publication.inspection, value: parseFloat(value)}})}
                            value={publication?.inspection?.value}
                            error={publication?.inspection?.error}
                            onEnter={onSavePublication}
                            icon="$"
                            loading={additionalCosts?.fetching === "loading"}
                        />
                    </div>
                    <ButtonShared
                        onPress={onSavePublication}
                        title="Guardar"
                        icon={<SaveOutlinedIcon className={styles.iconButton} />}
                        primary
                        disabled={additionalCosts.fetching === "loading"}
                        loading={additionalCosts.update.fetching === "loading"}
                        className={styles.saveButton}
                    />
                </div>
			</div>
        </div>
    )
}

export default memo(AdditionalCosts);
import { useEffect } from 'react';
import styles from './disabled.module.scss';
import { DisabledUsersProps } from '../../../interfaces/components';
import { capitalize } from '../../../helpers/formatters';
import ButtonShared from '../../../components/Button/Button';
import Modal from '../../../components/Modal';
import { useToast } from '../../../helpers/hooks';

const Disabled = ({
    open,
    setOpen,
    userSelected,
    loading,
    users,
    disabledUser,
    cleanDisabledUser
}: DisabledUsersProps) => {
    const toast = useToast();

    const onBack = () => {
        setOpen(false)
    }
    
    const onDisabled = () => {
        disabledUser(userSelected?._id, !userSelected?.disabled)
    }

    useEffect(() => {
        if(users?.disabled?.fetching === "failure"){
            cleanDisabledUser();
            if(users?.disabled?.error === "NOT_DISABLE_USER"){
                toast(`No puedes deshabiltar este usuario porque tiene una transacción o una oferta`);
                return;
            }
            toast(`Ha ocurrido un error al ${userSelected?.disabled ? "habilitar" : "deshabilitar"} el usuario`);
        }
        if(users?.disabled?.fetching === "success"){
            toast(`Usuario ${capitalize(userSelected?.name)} ${capitalize(userSelected?.last_name)} ${!userSelected?.disabled ? "deshabilitado" : "habilitado"} correctamente`);
            setOpen(false)
            cleanDisabledUser();
        }
    }, [users?.disabled?.fetching]);

    return (
        <Modal open={open} variant="white" className={styles.modal}>
            <p>
                <span className={styles.message}>Estás a punto de {userSelected?.disabled ? "habilitar" : "deshabilitar"} el siguiente usuario</span>
                <span className={styles.name}>{`${capitalize(userSelected?.name)} ${capitalize(userSelected?.last_name)}`}</span>
            </p>
            <div className={styles.buttons}>
                <ButtonShared 
                    onPress={onBack}
                    title="Regresar" 
                    tertiary
                    size="small"
                    disabled={users?.disabled?.fetching === "loading"}
                />
                <ButtonShared 
                    onPress={onDisabled}
                    title={userSelected?.disabled ? "Habilitar" : "Deshabilitar"}
                    primary
                    size="small"
                    loading={loading}
                />
            </div>
        </Modal>
    )
};
export default Disabled;
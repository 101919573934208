import React from "react";
import { RouteObject } from "../../interfaces/navigation";

const EntryPoint = React.lazy(() => import("../../containers/Dealerships/EntryPointContainer"));

export const dealershipsRoutes: RouteObject[] = [
    {
        title: "dealerships",
        path: "/dealerships",
        component: <EntryPoint />,
        protected: true
    }
];
import { Dispatch } from "@reduxjs/toolkit";
import { api } from "../config/api";
import { GetState } from "../config/store";
import { 
    getTermsRequest, 
    getTermsSuccess,
    getTermsFailure, 
    cleanGetTerms as cleanGetTermsReducer,
    updateTermsRequest, 
    updateTermsSuccess,
    updateTermsFailure, 
    cleanUpdateTerms as cleanUpdateTermsReducer
} from "../reducers/terms";
import { axiosClient } from "../config/axios";


export const getTerms = () => (dispatch: Dispatch) => _getTerms(dispatch);
const _getTerms = async (dispatch: Dispatch) => {
    try {
        dispatch({ type: getTermsRequest });

        const response = await axiosClient.get(`${api.settings}/terms-condition`);

        dispatch({ 
            type: getTermsSuccess, 
            payload: response?.data?.data?.terms_condition[response?.data?.data?.terms_condition.length - 1]
        });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getTermsFailure, payload: errorResponse });
    }
};

export const cleanGetTerms = () => (dispatch: Dispatch) => _cleanGetTerms(dispatch);
const _cleanGetTerms = async (dispatch: Dispatch) => dispatch({ type: cleanGetTermsReducer });

export const updateTerms = (file: any) => (dispatch: Dispatch) => _updateTerms(file, dispatch);
const _updateTerms = async (file: any, dispatch: Dispatch) => {
    try {
        dispatch({ type: updateTermsRequest });

        const bodyReq = JSON.stringify({
            type: "6388cf20018d2b4ad5af611d",
            file: file
        });

        const response = await axiosClient.post(`${api.settings}/terms-condition`, bodyReq);

        dispatch({ type: updateTermsSuccess, payload: response?.data?.data?.terms_condition});

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: updateTermsFailure, payload: errorResponse });
    }
};

export const cleanUpdateTerms = () => (dispatch: Dispatch) => _cleanUpdateTerms(dispatch);
const _cleanUpdateTerms = async (dispatch: Dispatch) => dispatch({ type: cleanUpdateTermsReducer });
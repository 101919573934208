import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import EntryPoint from "../../pages/Leads/EntryPoint";
import { LeadsContainerProps } from "../../interfaces/containers";
import { getLeads } from "../../actions/leads";

function mapStateToProps(state: LeadsContainerProps) {
    return{ 
        fetchingLeads: state.leads.fetching,
        leads: state.leads.leads
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getLeads
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(EntryPoint);
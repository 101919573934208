export const translations = (value: string) => {
    if (value === "admin-dealership" || value === "admin dealership") return "admin concesionaria";
    if (value === "admin-branch" || value === "admin branch") return "admin sucursal";
    if (value === "seller") return "vendedor";
    if (value === "final user" || value === "final-user") return "usuario final";
    if (value === "agent") return "agente";
    if (value === "pending") return "pendiente";
    if (value === "accepted") return "aceptado";
    if (value === "rejected") return "rechazado";
    if (value === "cancelled") return "cancelado";
    return "";
};

export const prorityTranslations = (priority: string) => {
    if (priority === "high") return "Alta";
    if (priority === "low") return "Baja";
    if (priority === "medium") return "Media";
    if (priority === "normal") return "Media";
    return "";
}
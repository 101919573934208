import React from "react";
import { RouteObject } from "../../interfaces/navigation";
import Terms from "../../containers/Settings/Terms/EntryPointContainer";
import AdditionalCosts from "../../containers/Settings/AdditionalCosts/EntryPointContainer";
import Payments from "../../containers/Settings/Payments/EntryPointContainer";
import PickupPoints from "../../containers/Settings/PickupPoints/EntryPointContainer";

const EntryPoint = React.lazy(() => import("./EntryPoint"));

export const settingsSubRoutes: RouteObject[] = [
    {
        title: "terms",
        path: "terms",
        component: <Terms />,
        protected: true
    },
    {
        title: "additionalCosts",
        path: "additionalCosts",
        component: <AdditionalCosts />,
        protected: true
    },
    {
        title: "payments",
        path: "payments",
        component: <Payments />,
        protected: true
    },
    {
        title: "pickupPoints",
        path: "pickupPoints",
        component: <PickupPoints />,
        protected: true
    }
];

export const settingsRoutes: RouteObject[] = [
    {
        title: "settings",
        path: "/settings",
        component: <EntryPoint />,
        protected: true,
        subRoutes: settingsSubRoutes
    }
];
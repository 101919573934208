import { Dispatch } from "@reduxjs/toolkit";
import { api } from "../config/api";
import { GetState } from "../config/store";
import { 
    getBranchsRequest,
    getBranchsSuccess,
    getBranchsFailure,
    cleanGetBranchs as cleanGetBranchsReducer,
} from "../reducers/branchs";
import { axiosClient } from "../config/axios";

export const getBranchs = (id: string) => (dispatch: Dispatch) => _getBranchs(id, dispatch);
const _getBranchs = async (id: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: getBranchsRequest });

        const response = await axiosClient.get(`${api.users}/branchs/dealership/${id}`);

        dispatch({ type: getBranchsSuccess, payload: response?.data?.data?.branch});

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getBranchsFailure, payload: errorResponse });
    }
};

export const cleanGetBranchs = () => (dispatch: Dispatch) => _cleanGetBranchs(dispatch);
const _cleanGetBranchs = async (dispatch: Dispatch) => dispatch({type: cleanGetBranchsReducer});
export const parseToRut = (identifier: any) => {
    if(identifier.length === 9){
        var parsedIdentifier = identifier.substring(0,identifier.length-1)+"-"+identifier.substring(identifier.length-1);
        return String(parsedIdentifier).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    else{
        return String(identifier).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        
    }
};
type CheckerTypes = "onlyLetters" | "onlyNumbers";

export const formatWithPoints = (value: string) => value.split(".").join("").replace(/\B(?=(\d{3})+(?!\d))/g, ".");

export const checkerType = (value: any, checker: CheckerTypes = "onlyLetters") => {
    if(checker === "onlyLetters"){
        return /^[a-zA-Z\s]*$/.test(value);
    }
    if(checker === "onlyNumbers"){
        return /^[0-9\s]*$/.test(value);
    }
    return true;
}

export const capitalize = (word: string | undefined) => {
    if (!word) return;

    const lowecaseWord = word.toLowerCase();
    const firstCapitalLetter = word.charAt(0).toUpperCase();
    const capitalizeWord = firstCapitalLetter + lowecaseWord.slice(1)
    return capitalizeWord;
};

export const clean = (rut: string) => {
    return typeof rut === 'string'
        ? rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase()
        : ''
}

export const formatRut = (rut: string, options = {
    dots: true
}) => {
    rut = clean(rut)
  
    let result
    if (options.dots) {
        result = rut.slice(-4, -1) + '-' + rut.substring(rut.length - 1)
        for (let i = 4; i < rut.length; i += 3) {
            result = rut.slice(-3 - i, -i) + '.' + result
        }
    } else {
        result = rut.slice(0, -1) + '-' + rut.substring(rut.length - 1)
    }

    return result;
};

export const validateRut = (rut: string) => {
    if (typeof rut !== 'string') {
        return false
    }

    // if it starts with 0 we return false
    // so a rut like 00000000-0 will not pass
    if (/^0+/.test(rut)) {
        return false
    }

    if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(rut)) {
        return false
    }

    rut = clean(rut)

    let t = parseInt(rut.slice(0, -1), 10)
    let m = 0
    let s = 1

    while (t > 0) {
        s = (s + (t % 10) * (9 - (m++ % 6))) % 11
        t = Math.floor(t / 10)
    }

    const v: string = s > 0 ? '' + (s - 1) : 'K'
    return v === rut.slice(-1)
};

export const addPointsInText = (number: string | number | undefined) => {
    if (!number) return "0";
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
};

export const removePointsInText = (number: string | number | undefined) => {
    if (!number) return "0";
    return number.toString().replace(/\./g, '');
};

export const getYears = (limitYear: number = 5) => {
    const now = new Date().getUTCFullYear();
    return Array(now - (now - limitYear)).fill('').map((v, idx) => now - idx) as Array<number>;
}
import { createSlice } from "@reduxjs/toolkit";
import { AuthStateProps } from "../interfaces/auth";

const initialState: AuthStateProps = {
    login: {
        fetching: "unknown",
        error: ""    
    },
    user: null,
    refreshToken: "",
    fetchingRefreshToken: "unknown",
    refreshType: "first_initialization",
    expirationTime: null,
    token: ""
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        loginRequest: (state) => {
            state.login.fetching = "loading";
            state.login.error = initialState.login.error;
        },
        loginSuccess: (state, action) => {
            state.login.fetching = "success";
            state.login.error = initialState.login.error;
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.refreshToken = action.payload.refreshToken;
            state.expirationTime = action.payload.expirationTime;
        },
        loginFailure: (state, action) => {
            state.login.fetching = "failure";
            state.login.error = action.payload;
        },
        cleanLogin: (state) => {
            state.login.fetching = initialState.login.fetching;
        },
        logout: (state) => {
            state.login.fetching = initialState.login.fetching;
            state.login.error = initialState.login.error;
            state.token = initialState.token;
            state.expirationTime = initialState.expirationTime;
            state.refreshToken = initialState.refreshToken;
            state.user = initialState.user;
        },
        refreshTokenRequest: (state, action) => {
            state.fetchingRefreshToken = "loading";
            state.refreshType = action.payload;
        },
        refreshTokenSuccess: (state, action) => {
            state.fetchingRefreshToken = "success";
            state.refreshToken = action.payload.refreshToken;
            state.token = action.payload.token;
            state.expirationTime = action.payload.expirationTime;
            state.refreshType = action.payload.refreshType;
            state.user = action.payload.user;
        },
        refreshTokenFailure: (state) => {
            state.fetchingRefreshToken = "failure";
        },
        initRefreshType: (state) => {
            state.refreshType = "";
        }
    },
});

export const {
    loginRequest,
    loginSuccess,
    loginFailure,
    cleanLogin,
    logout,
    refreshTokenRequest,
    refreshTokenSuccess,
    refreshTokenFailure,
    initRefreshType
} =
    authSlice.actions;

export default authSlice.reducer;

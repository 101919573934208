import { useEffect, useState } from 'react';
import styles from "./caracteristic.module.scss";
import { Skeleton } from '@mui/material';
import { CaracteristicProps } from "../../interfaces/components";
import { capitalize } from "../../helpers/formatters";
import { CARACTERISTICS, TRANSMISSIONS } from "../../constants/publication";

const Caracteristic = ({
    type,
    value,
    loading = false
}: CaracteristicProps) => {
    const [caracteristicItem, setCaracteristicItem] = useState<any>();
    
    useEffect(() => {
        setCaracteristicItem(CARACTERISTICS.find((carac: any) => carac.type === type));
    }, [type]);
    
    return (
        <>
        {
            loading
            ? <Skeleton animation="wave" variant="rounded" className={styles.skeleton} />
            : 
            caracteristicItem &&
            <div className={styles.caracteristicContainer}>
                <img src={caracteristicItem?.image} alt={caracteristicItem?.title} />
                <p>
                    <span className={styles.title}>{capitalize(caracteristicItem?.title)}</span>
                    <span className={styles.value}>{type === "transmission" ? (TRANSMISSIONS[value ?? 0] ?? value) : capitalize(value)}</span>
                </p>
            </div>
        }
        </>
    )
}
export default Caracteristic;
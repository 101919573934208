import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import EntryPoint from "../../pages/publication";
import { PublicationContainerProps } from "../../interfaces/containers";
import { 
    getPublication, 
    cleanGetPublication, 
    getPublicationArticles,
    cleanGetPublicationArticles,
    putStatusPublication,
    cleanPutStatusPublication,
    deletePublication,
    getOfferAccepted,
    cleanDeletePublication
} from "../../actions/publication";
import { getPublicationsByStatus } from "../../actions/publications";

function mapStateToProps(state: PublicationContainerProps) {
    return{
        publication: state.publication
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getPublication, 
        cleanGetPublication,
        getPublicationArticles,
        cleanGetPublicationArticles,
        putStatusPublication,
        cleanPutStatusPublication,
        deletePublication,
        getOfferAccepted,
        cleanDeletePublication,
        getPublicationsByStatus
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(EntryPoint);
import { Dispatch } from "@reduxjs/toolkit";
import { CheckerTypes } from "../interfaces/types";
import { unauthorized } from "../reducers/unauthorized";

export const capitalize = (word: string | undefined) => {
    if (!word) return;

    const lowecaseWord = word.toLowerCase();
    const firstCapitalLetter = word.charAt(0).toUpperCase();
    const capitalizeWord = firstCapitalLetter + lowecaseWord.slice(1)
    return capitalizeWord;
};

export const parseToRut = (identifier: any) => {
    if (identifier.length === 9) {
        var parsedIdentifier = identifier.substring(0, identifier.length - 1) + "-" + identifier.substring(identifier.length - 1);
        return String(parsedIdentifier).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    else {
        return String(identifier).replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    }
};

export const formatInput = (value: any) => {
    var num = value.replace(/\./g, ''); // Quita los puntos
    var onlyNumber = num;

    if (isNaN(num)) { // Si tiene letra
        onlyNumber = num.replace(/[^\d\.]*/g, ''); // Quita las letras
        num = onlyNumber;
    }

    // Agrega puntos correctamente
    num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
    num = num.split('').reverse().join('').replace(/^[\.]/, '');
    var formattedNumber = num;

    return { onlyNumber, formattedNumber };
}

export const checkerType = (value: any, checker: CheckerTypes = "onlyLetters") => {
    if (checker === "onlyLetters") {
        return /^[a-zA-Z\s]*$/.test(value);
    }
    if (checker === "onlyNumbers") {
        return /^[0-9\s]*$/.test(value);
    }
    return true;
}

export const onError = (err: any) => {
    let error: any = new Error(err?.response?.data?.error?.message, { cause: err?.response?.status })
    error.statusCode = err?.response?.status;
    error.message = err?.response?.data?.error?.message;
    error.name = err?.response?.status;
    if (err?.response?.status === 401) {
        localStorage.removeItem("auth");
        localStorage.removeItem("token");
        localStorage.setItem("error", "401");
        Promise.reject();
        window.open("/login", "_self")
        throw error;
    }
    Promise.reject();
    throw error;
}

export const catch401 = (code: any, dispatch: Dispatch) => {
    if (code === 401) {
        dispatch({ type: unauthorized });
        return;
    }
}

export const testMerge = (firstArr: any, secondArr: any) => {

    const mergedArray = [...firstArr, ...secondArr];
    const uniqueArray = mergedArray.filter((item, index, self) =>
        index === self.findIndex((t) => t._id === item._id)
    );
    return uniqueArray
}
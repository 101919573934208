import styles from './tagsLoader.module.scss';
import { Skeleton } from "@mui/material";
import Tag from '../../../Tag';

const TagsLoader = () => {

    return (
        <div className={styles.skeletonContainer}>
            <Skeleton animation="wave" variant="text" className={styles.title} />
            <div className={styles.content}>
                <Tag loading />
                <Tag loading />
                <Tag loading />
                <Tag loading />
            </div>
        </div>
    )
}

export default TagsLoader;
export const dev = process.env.REACT_APP_NODE_ENV === 'development';

interface IApi {
    publications: string,
    users: string,
    autoRed: string,
    pns: string,
    transactions: string,
    bikleta: string,
    notifications: string,
    payments: string,
    offers: string,
    credits: string,
    settings: string,
    inspections: string,
    openai: string,
    records: string,
    reports: string,
    leads: string,
    mapsGoogle: string
}

export const api: IApi = {
    publications: "publications",
    users: "users",
    autoRed: "vehicles",
    pns: process.env.REACT_APP_API_PNS || "",
    transactions: "transactions",
    bikleta: process.env.REACT_APP_API_BIKLETA || "",
    notifications: "notifications",
    offers: "publications",
    payments: "payments",
    credits: "credits",
    settings: "settings",
    inspections: "inspections",
    openai: "openai",
    records: "records",
    reports: "reports",
    leads: "leads",
    mapsGoogle: process.env.REACT_APP_API_MAPS_GOOGLE || ""
}
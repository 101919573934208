import React from "react";
import { RouteObject } from "../../interfaces/navigation";

const EntryPoint = React.lazy(() => import("../../containers/Publications/EntryPointContainer"));

export const publicationsRoutes: RouteObject[] = [
    {
        title: "publications",
        path: "/publications/:type",
        component: <EntryPoint />,
        protected: true
    }
];
import { useEffect, useMemo, useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import styles from "./entryPoint.module.scss";
import { LeadsProps } from "../../../interfaces/pages";
import { CircularProgress } from "@mui/material";
import LeadCard from "./LeadCard";
import { FAKE_LEAD } from "../../../constants/leads";
import PageCard from "../../../components/PageCard";
import Loader from "../../../components/Loader";
import moment from "moment";
import ButtonShared from "../../../components/Button/Button";
import { current } from "@reduxjs/toolkit";
let interval: any;

const EntryPoint = ({
    leads,
    getLeads,
    fetchingLeads
}: LeadsProps) => {
    const navigate = useNavigate();
    const params = useParams();
    const [filter, setFilter] = useState("7")
    const startDate = useRef("");
    const endDate = useRef("");
    const stringQuery = useRef({
        opportunityPage: 1,
        registerPage: 1,
        offerPage: 1,
        schedulingPage: 1,
        matchPage: 1,
        soldPage: 1,
        not_interestedPage: 1
    })

    const LEAD_COLUMNS = useMemo(() => {
        return [
            {
                id: "opportunity",
                label: "Oportunidad",
                data: leads?.["opportunity"],
                color: "82, 0, 255",
                stringQuery: "opportunityPage"
            },
            {
                id: "offer",
                label: "Oferta",
                data: leads?.["offer"],
                color: "255, 94, 0",
                stringQuery: "offerPage"
            },
            {
                id: "scheduling",
                label: "Agendamiento",
                data: leads?.["scheduling"],
                color: "13, 154, 255",
                stringQuery: "schedulingPage"
            },
            {
                id: "match",
                label: "Match",
                data: leads?.["match"],
                color: "241, 205, 0",
                stringQuery: "matchPage"
            },
            {
                id: "sold",
                label: "Entrega",
                data: leads?.["sold"],
                color: "255, 0, 123",
                stringQuery: "soldPage"
            }
        ]
    }, [leads]);

    type StringQueryKeys = keyof typeof stringQuery.current;

    const pressShowMore = (stringQueryText: StringQueryKeys) => {
        stringQuery.current[stringQueryText] = stringQuery.current[stringQueryText] + 1
        getLeads("reloading", startDate.current, endDate.current, stringQuery.current);
    }

    const pressFilter = (filterText: string) => {
        if (filterText === 'all') {
            getLeads('filter', "", "");
            startDate.current = ""
            endDate.current = ""
            setFilter("all");
        }
        if (filterText === '90') {
            getLeads('filter', moment().subtract(90, 'd').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
            startDate.current = moment().subtract(90, 'd').format('YYYY-MM-DD')
            endDate.current = moment().format('YYYY-MM-DD');
            setFilter("90");
        }
        if (filterText === '30') {
            getLeads('filter', moment().subtract(30, 'd').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
            startDate.current = moment().subtract(30, 'd').format('YYYY-MM-DD')
            endDate.current = moment().format('YYYY-MM-DD');
            setFilter("30");
        }
        if (filterText === '15') {
            getLeads('filter', moment().subtract(15, 'd').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
            startDate.current = moment().subtract(15, 'd').format('YYYY-MM-DD')
            endDate.current = moment().format('YYYY-MM-DD');
            setFilter("15");
        }
        if (filterText === '7') {
            getLeads('filter', moment().subtract(7, 'd').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
            startDate.current = moment().subtract(7, 'd').format('YYYY-MM-DD')
            endDate.current = moment().format('YYYY-MM-DD');
            setFilter("7");
        }
        if (filterText === '1') {
            getLeads('filter', moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
            startDate.current = moment().format('YYYY-MM-DD')
            endDate.current = moment().format('YYYY-MM-DD');
            setFilter("1");
        }

    }
    useEffect(() => {
        getLeads('filter', moment().subtract(7, 'd').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
        interval = setInterval(() => {
            getLeads("reloading", startDate.current, endDate.current);
        }, 60000);
        return () => clearInterval(interval);
    }, [getLeads]);

    useEffect(() => {
        if (fetchingLeads === "failure") {
            clearInterval(interval);
        }
    }, [fetchingLeads]);

    return (
        params.id ? <Outlet /> :
            <>
                {fetchingLeads === "loading"
                    ?
                    <Loader text="Cargando leads" className={styles.loader} />
                    :
                    <PageCard className={styles.leadCardContainer}>
                        <div className={styles.filter}>
                            <ButtonShared tertiary={filter !== 'all'} className={`${styles.button} ${filter === 'all' && styles.border}`} title={"Todos"} onPress={() => pressFilter("all")} />
                            <ButtonShared tertiary={filter !== '90'} className={`${styles.button} ${filter === '90' && styles.border}`} title={"3 meses"} onPress={() => pressFilter("90")} />
                            <ButtonShared tertiary={filter !== '30'} className={`${styles.button} ${filter === '30' && styles.border}`} title={"1 mes"} onPress={() => pressFilter("30")} />
                            <ButtonShared tertiary={filter !== '15'} className={`${styles.button} ${filter === '15' && styles.border}`} title={"15 dias"} onPress={() => pressFilter("15")} />
                            <ButtonShared tertiary={filter !== '7'} className={`${styles.button} ${filter === '7' && styles.border}`} title={"7 dias"} onPress={() => pressFilter("7")} />
                            <ButtonShared tertiary={filter !== '1'} className={`${styles.button} ${filter === '1' && styles.border}`} title={"Hoy"} onPress={() => pressFilter("1")} />
                        </div>
                        <div className={styles.leadsContainer}>
                            <div className={styles.list}>
                                {
                                    LEAD_COLUMNS.map((leadItem, index) => (
                                        <div className={`${styles.leadColumnContainer} ${LEAD_COLUMNS.length === index + 1 && styles.removeMargin}`}>
                                            <p style={{ color: "black" }} className={styles.leadTitle}>
                                                {leadItem.label}
                                                <div style={{ backgroundColor: "black" }} className={styles.ball} >
                                                    <span className={styles.columnCount}>
                                                        {leadItem?.data?.total}
                                                    </span>
                                                </div>
                                                {
                                                    fetchingLeads === "reloading" &&
                                                    <CircularProgress
                                                        className={styles.leadsLoader}
                                                        classes={{
                                                            colorPrimary: styles.leadsLoader
                                                        }}
                                                        style={{
                                                            'color': "black"
                                                        }}
                                                    />
                                                }
                                            </p>
                                            <div className={styles.itemsContainer}>
                                                {
                                                    (!leadItem?.data || !leadItem?.data?.leads?.length) ? <LeadCard fake={true} onClick={() => { }} item={FAKE_LEAD} /> : leadItem.data?.leads?.map((item: any) => (
                                                        <LeadCard onClick={(link) => navigate(`/publication/${link.split("/")[link.split("/").length - 1]}`)} item={item} />
                                                    ))
                                                }
                                                {leadItem?.data?.leads?.length < leadItem?.data?.total
                                                    &&
                                                    //@ts-ignore
                                                    <ButtonShared secondary className={styles.showMoreButton} title={"Ver más"} onPress={() => pressShowMore(leadItem?.stringQuery)} />
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </PageCard>
                }
            </>
    )
};
export default EntryPoint;
export const FAKE_LEAD = {
    title: "Ford Fiesta Kinetic Design 1.6",
    user: "-",
    email: "fcalle@denwaip.com",
    brand: "Ford",
    model: "Fiesta Kinetic Design 1.6",
    phone: "351-2811246",
    priority: "high",
    picture: "https://res.cloudinary.com/gtt-dev/image/upload/v1700653676/uploads/plbblazeqzjuduccnl3e.webp",
    mileage: 82000,
    year: 2018,
    by: "Chileautos",
    price: 11125000,
    plate: "GKSB78",
    link: "https://test.linze.cl/publication/655deab7836c3d8e57ad0907"
};

export const TIME_LINE_OPTIONS = [{
    id: "oportunity",
    title: "Oportunidad",
    date: "18-11-2023"
},
{
    id: "register",
    title: "Registro",
    date: "20-11-2023"
},
{
    id: "offer",
    title: "Oferta",
    date: "21-11-2023"
},
{
    id: "scheduled",
    title: "Agendamiento",
    date: "22-11-2023"
},
{
    id: "match",
    title: "Match",
    date: "23-11-2023"
},
{
    id: "delivery",
    title: "Entrega",
    date: "24-11-2023"
}];
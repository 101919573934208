import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { AuthContainerProps } from "../../interfaces/containers";
import NavBar from "../../Layout/NavBar/NavBar";

function mapStateToProps(state: AuthContainerProps) {
    return{
        user: state.auth.user
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({

    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
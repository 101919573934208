import { createSlice } from "@reduxjs/toolkit";
import { AdditionalCostsStateProps } from "../interfaces/additionalCosts";

const initialState: AdditionalCostsStateProps = {
    fetching: "unknown",
    data: {
        financing: [],
        cash: [],
        publication: [],
    },
    error: "",
    update: {
        fetching: "unknown",
        error: ""
    }
};

const additionalCostsSlice = createSlice({
    name: "additionalCosts",
    initialState,
    reducers: {
        getAdditionalCostsRequest: (state) => {
            state.fetching = "loading";
        },
        getAdditionalCostsSuccess: (state, action) => {
            state.fetching = "success";
            if(action.payload.flow === "financing"){
                state.data.financing = action.payload.data
            }
            if(action.payload.flow === "cash"){
                state.data.cash = action.payload.data
            }
            if(action.payload.flow === "publication"){
                state.data.publication = action.payload.data
            }
        },
        getAdditionalCostsFailure: (state, action) => {
            state.fetching = "failure";
            state.error = action.payload;
        },
        cleanGetAdditionalCosts: (state) => {
            state.fetching = initialState.fetching;
        },
        updateAdditionalCostsRequest: (state) => {
            state.update.fetching = "loading";
        },
        updateAdditionalCostsSuccess: (state, action) => {
            state.update.fetching = "success";
            /* state.data = action.payload; */
        },
        updateAdditionalCostsFailure: (state, action) => {
            state.update.fetching = "failure";
            state.update.error = action.payload;
        },
        cleanUpdateAdditionalCosts: (state) => {
            state.update.fetching = initialState.update.fetching;
        }
    },
});

export const {
    getAdditionalCostsRequest,
    getAdditionalCostsSuccess,
    getAdditionalCostsFailure,
    cleanGetAdditionalCosts,
    updateAdditionalCostsRequest,
    updateAdditionalCostsSuccess,
    updateAdditionalCostsFailure,
    cleanUpdateAdditionalCosts
} =
    additionalCostsSlice.actions;

export default additionalCostsSlice.reducer;

import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { images } from "../../constants/images";
import styles from "./navBar.module.scss";
import { navOptions } from "../../constants/navigation";
/* import Notifications from "../../containers/Notifications/NotificationsContainer"; */
import { NavBarProps } from "../../interfaces/components";
import Dropdown from "../../components/Dropdown/Dropdown";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { FaRegBell, FaBell } from 'react-icons/fa';
import { useWindowSize } from "use-hooks";
import Badge from '@mui/material-next/Badge';
import { capitalize } from "../../helpers/formatters";

const NavBar = ({
    user
}: NavBarProps) => {
    const navigate = useNavigate();
    const windowSize = useWindowSize();
    const [openMenu, setOpenMenu] = useState(false);
    const [openNotifications, setOpenNotifications] = useState(false);
    const [openProfile, setOpenProfile] = useState(false);

    return (
        <div className={styles.navBarContainer}>
            <NavLink className={styles.linzeLink} to={"/dashboard"}>
                <img src={images.logoIsotipo} className={styles.logo} alt="Logo" />
            </NavLink>
            <IconButton className={styles.menu} onClick={() => setOpenMenu(true)}>
                <MenuIcon className={styles.icon} />
            </IconButton>
            <div id="navBarOptions" className={`${styles.navBarLabels} ${openMenu && styles.open}`}>
                {/* {
                    navOptions.map((option: any, index: number) => {
                        if (option.to === "/notifications" && windowSize.width > 1024) {
                            let unreaded = 0;
                            return (
                                <div className={styles.notifications} key={index}>
                                    {
                                        notifications && notifications.forEach((item: any) => unreaded = (!item?.read) ? unreaded + 1 : unreaded)
                                    }
                                    {
                                        unreaded > 0 &&
                                        <Badge 
                                            badgeContent={unreaded} 
                                            color="error" 
                                            classes={{ 
                                                root: styles.unReaded,
                                                badge: styles.badge
                                            }} 
                                        />
                                    }
                                    <Dropdown
                                        type="icon"
                                        icon={openNotifications ? <FaBell className={styles.notificationsIcon} /> : <FaRegBell className={styles.notificationsIcon} />}
                                        onPress={() => setOpenNotifications(!openNotifications)}
                                        className={styles.dropdown}
                                        id={`link-${option.id}`}
                                    >
                                        <Notifications />
                                    </Dropdown>
                                </div>
                            )
                        }
                    })
                } */}
                <div className={`${styles.profile} ${openProfile && styles.open}`} onClick={() => navigate("/dashboard")}>
                    <img src={user?.picture ?? images.profile} className={styles.image} alt="user" />
                    <p className={styles.data}>
                        <span className={styles.name}>{`${capitalize(user?.name)} ${capitalize(user?.last_name)}`}</span>
                        <span className={styles.role}>Administrador</span>
                    </p>
                </div>
            </div>
            <div className={`${styles.bkgr} ${openMenu && styles.open}`} onClick={() => setOpenMenu(false)}></div>
        </div>
    )
}
export default NavBar;
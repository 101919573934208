import React, { useState } from "react";
import { LeadCardProps } from "../../../../interfaces/components";
import styles from './leadCard.module.scss';
import Icon from '@mdi/react';
import { images } from "../../../../constants/images";
import { mdiChevronDoubleDown, mdiChevronDoubleUp, mdiDragHorizontalVariant } from "@mdi/js";
import { addPointsInText } from "../../../../helpers/formatters";
import { prorityTranslations } from "../../../../constants/translactions";

const LeadCard = ({
    item,
    onClick,
    fake = false
}: LeadCardProps) => {

    
    const [image, setImage] = useState(item.image)
    return (
        <div className={`${styles.cardContainer} ${fake && styles.fake}`} onClick={() => !fake && onClick(item?.publication || "")}>
            <div className={styles.leftContainer}>
                <img className={styles.cardPicture} alt={item?.title} src={image ?? images.noImagePublication} onError={() => setImage(images.noImagePublication)} />
                <div className={styles.plateContainer}>
                    <p>{item.plate.substring(0, 2)}</p><p className={styles.platePoint}>.</p><p>{item.plate.substring(2, 4)}</p><p className={styles.platePoint}>.</p><p>{item.plate.substring(4, 6)}</p>
                </div>
            </div>
            <div className={styles.rightContainer}>
                <div className={styles.userContainer}>
                    <img src={images.account} alt="user" className={styles.picture} />
                    <p>{item.name?.split(" ")[0]}</p>
                </div>
                <p className={styles.title}>{item.title}</p>
                <p className={styles.price}>{addPointsInText(item.price)}</p>
                <p className={styles.status}>{item?.substate?.name ?? `-`}</p>
                <div className={styles.bottomContainer}>
                    <div className={styles.bottomLeftContainer}>
                        <p>{item?.[`updated_at `] ? item?.[`updated_at `]?.split("T")[0] : "-"}</p>
                        <p>Por {item?.come_from}</p>
                    </div>
                    <Icon path={
                        item.priority === "high" ? mdiChevronDoubleUp
                            :
                            item.priority === "low" ? mdiChevronDoubleDown
                                : mdiDragHorizontalVariant
                    } title={prorityTranslations(item?.priority)} className={`${styles.priority} ${styles[`${item.priority}`]}`} />
                </div>
            </div>
        </div>
    )
};

export default LeadCard;
import { createSlice } from "@reduxjs/toolkit";
import { PaymentsStateProps } from "../interfaces/payments";

const initialState: PaymentsStateProps = {
    fetching: "unknown",
    data: [],
    error: "",
    update: {
        fetching: "unknown",
        error: ""
    }
};

const paymentsSlice = createSlice({
    name: "payments",
    initialState,
    reducers: {
        getPaymentsRequest: (state) => {
            state.fetching = "loading";
        },
        getPaymentsSuccess: (state, action) => {
            state.fetching = "success";
            state.data = action.payload;
        },
        getPaymentsFailure: (state, action) => {
            state.fetching = "failure";
            state.error = action.payload;
        },
        cleanGetPayments: (state) => {
            state.fetching = initialState.fetching;
        },
        updatePaymentsRequest: (state) => {
            state.update.fetching = "loading";
        },
        updatePaymentsSuccess: (state, action) => {
            state.update.fetching = "success";
            state.data = action.payload;
        },
        updatePaymentsFailure: (state, action) => {
            state.update.fetching = "failure";
            state.update.error = action.payload;
        },
        cleanUpdatePayments: (state) => {
            state.update.fetching = initialState.update.fetching;
        }
    },
});

export const {
    getPaymentsRequest,
    getPaymentsSuccess,
    getPaymentsFailure,
    cleanGetPayments,
    updatePaymentsRequest,
    updatePaymentsSuccess,
    updatePaymentsFailure,
    cleanUpdatePayments
} =
    paymentsSlice.actions;

export default paymentsSlice.reducer;

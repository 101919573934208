import { createSlice } from "@reduxjs/toolkit";
import { DealershipsStateProps } from "../interfaces/dealerships";

const initialState: DealershipsStateProps = {
    list: {
        fetching: "unknown",
        data: [],
        total: 0,
        error: ""
    },
    add: {
        fetching: "unknown",
        data: [],
        error: ""
    },
    edit: {
        fetching: "unknown",
        data: [],
        error: ""
    },
    delete: {
        fetching: "unknown",
        error: ""
    }
};

const dealershipsSlice = createSlice({
    name: "dealerships",
    initialState,
    reducers: {
        getDealershipsRequest: (state) => {
            state.list.fetching = "loading";
        },
        getDealershipsSuccess: (state, action) => {
            state.list.fetching = "success";
            state.list.data = action.payload.dealerships;
            state.list.total = action.payload.total;
        },
        getDealershipsFailure: (state, action) => {
            state.list.fetching = "failure";
            state.list.error = action.payload;
        },
        cleanGetDealerships: (state) => {
            state.list.fetching = initialState.list.fetching;
        },
        addDealershipRequest: (state) => {
            state.add.fetching = "loading";
        },
        addDealershipSuccess: (state, action) => {
            state.add.fetching = "success";
            state.add.data = action.payload.dealerships;
        },
        addDealershipFailure: (state, action) => {
            state.add.fetching = "failure";
            state.add.error = action.payload;
        },
        cleanAddDealership: (state) => {
            state.add.fetching = initialState.add.fetching;
        },
        editDealershipRequest: (state) => {
            state.edit.fetching = "loading";
        },
        editDealershipSuccess: (state, action) => {
            state.edit.fetching = "success";
            state.edit.data = action.payload.dealerships;
        },
        editDealershipFailure: (state, action) => {
            state.edit.fetching = "failure";
            state.edit.error = action.payload;
        },
        cleanEditDealership: (state) => {
            state.edit.fetching = initialState.edit.fetching;
        },
        deleteDealershipRequest: (state) => {
            state.delete.fetching = "loading";
        },
        deleteDealershipSuccess: (state) => {
            state.delete.fetching = "success";
        },
        deleteDealershipFailure: (state, action) => {
            state.delete.fetching = "failure";
            state.delete.error = action.payload;
        },
        cleanDeleteDealership: (state) => {
            state.delete.fetching = initialState.delete.fetching;
        },
    },
});

export const {
    getDealershipsRequest,
    getDealershipsSuccess,
    getDealershipsFailure,
    cleanGetDealerships,
    addDealershipRequest,
    addDealershipSuccess,
    addDealershipFailure,
    cleanAddDealership,
    editDealershipRequest,
    editDealershipSuccess,
    editDealershipFailure,
    cleanEditDealership,
    deleteDealershipRequest,
    deleteDealershipSuccess,
    deleteDealershipFailure,
    cleanDeleteDealership
} =
    dealershipsSlice.actions;

export default dealershipsSlice.reducer;

import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { AppContainerProps } from "../../interfaces/containers";
import App from "../../components/App";

function mapStateToProps(state: AppContainerProps) {
    return{
        user: state.auth.user
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({

    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
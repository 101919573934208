import { Dispatch } from "@reduxjs/toolkit";
import { api } from "../config/api";
import { GetState } from "../config/store";
import { 
    getUsersRequest,
    getUsersSuccess,
    getUsersFailure,
    cleanGetUsers as cleanGetUsersReducer,
    addUserRequest,
    addUserSuccess,
    addUserFailure,
    cleanAddUser as cleanAddUserReducer,
    editUserRequest,
    editUserSuccess,
    editUserFailure,
    cleanEditUser as cleanEditUserReducer,
    disabledUserRequest,
    disabledUserSuccess,
    disabledUserFailure,
    cleanDisabledUser as cleanDisabledUserReducer,
    deleteUserRequest,
    deleteUserSuccess,
    deleteUserFailure,
    cleanDeleteUser as cleanDeleteUserReducer,
    getReportsRequest,
    getReportsSuccess,
    getReportsFailure
} from "../reducers/users";
import { UserSidebarProps } from "../interfaces/users";
import { UsersValidTypes } from "../interfaces/types";
import { axiosClient } from "../config/axios";

export const getUsers = (data: any) => (dispatch: Dispatch) => _getUsers(data, dispatch);
const _getUsers = async (data: any, dispatch: Dispatch) => {
    try {
        dispatch({ type: getUsersRequest });
        let limit = data?.limit ?? 10;
        let search = data?.search;
        let page = data?.page ?? 1;

        let url = `${api.users}?page=${page}&limit=${limit}`;
        if(search){
            url = url.concat(`&text=${search}`);
        }

        const response = await axiosClient.get(url);

        dispatch({ 
            type: getUsersSuccess, 
            payload: {
                users: response?.data?.data?.users,
                total: response?.data?.data?.total
            }
        });

    } catch (error: any) {
        console.log(error);
        dispatch({ type: getUsersFailure, payload: error });
    }
};

export const cleanGetUsers = () => (dispatch: Dispatch) => _cleanGetUsers(dispatch);
const _cleanGetUsers = async (dispatch: Dispatch) => dispatch({type: cleanGetUsersReducer});

export const addUser = (user: UserSidebarProps, typeUser?: UsersValidTypes) => (dispatch: Dispatch) => _addUser(user, typeUser, dispatch);
const _addUser = async (user: UserSidebarProps, typeUser: UsersValidTypes = "normal", dispatch: Dispatch) => {
    try {
        dispatch({ type: addUserRequest });

        const body = JSON.stringify(user);

        const response = await axiosClient.post(`${api.users}${typeUser === "B2C" ? "/by-admin" : ""}`, body);

        dispatch({ 
            type: addUserSuccess, 
            payload: response?.data?.data
        });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: addUserFailure, payload: errorResponse });
    }
};

export const cleanAddUser = () => (dispatch: Dispatch) => _cleanAddUser(dispatch);
const _cleanAddUser = async (dispatch: Dispatch) => dispatch({type: cleanAddUserReducer});

export const editUser = (id: string, user: UserSidebarProps, typeUser?: UsersValidTypes) => (dispatch: Dispatch) => _editUser(id, user, typeUser, dispatch);
const _editUser = async (id: string, user: UserSidebarProps, typeUser: UsersValidTypes = "normal", dispatch: Dispatch) => {
    try {
        dispatch({ type: editUserRequest });

        const body = JSON.stringify(user);

        const response = await axiosClient.patch(`${api.users}${typeUser === "B2C" ? "/by-admin" : ""}/${id}`, body);

        dispatch({ type: editUserSuccess, payload: response?.data?.data });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: editUserFailure, payload: errorResponse });
    }
};

export const cleanEditUser = () => (dispatch: Dispatch) => _cleanEditUser(dispatch);
const _cleanEditUser = async (dispatch: Dispatch) => dispatch({type: cleanEditUserReducer});

export const disabledUser = (id: string, disabled: boolean) => (dispatch: Dispatch) => _disabledUser(id, disabled, dispatch);
const _disabledUser = async (id: string, disabled: boolean, dispatch: Dispatch) => {
    try {
        dispatch({ type: disabledUserRequest });

        const body = JSON.stringify({ disabled: disabled });

        const response = await axiosClient.patch(`${api.users}/${id}`, body);

        dispatch({ 
            type: disabledUserSuccess, 
            payload: response?.data?.data
        });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: disabledUserFailure, payload: errorResponse });
    }
};

export const cleanDisabledUser = () => (dispatch: Dispatch) => _cleanDisabledUser(dispatch);
const _cleanDisabledUser = async (dispatch: Dispatch) => dispatch({type: cleanDisabledUserReducer});

export const deleteUser = (id: string) => (dispatch: Dispatch) => _deleteUser(id, dispatch);
const _deleteUser = async (id: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: deleteUserRequest });

        const response = await axiosClient.delete(`${api.users}/${id}`);

        dispatch({ type: deleteUserSuccess, payload: response?.data?.data});

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: deleteUserFailure, payload: errorResponse });
    }
};

export const cleanDeleteUser = () => (dispatch: Dispatch) => _cleanDeleteUser(dispatch);
const _cleanDeleteUser = async (dispatch: Dispatch) => dispatch({type: cleanDeleteUserReducer});

export const getReports = () => (dispatch: Dispatch) => _getReports(dispatch);
const _getReports = async (dispatch: Dispatch) => {
    try {
        dispatch({ type: getReportsRequest });

        const response = await axiosClient.post(`${api.reports}/users/reports`, {}, {
            responseType: 'blob'
        });

        // Create blob and download
        const blob = new Blob([response.data], { 
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        
        // Format current date
        const now = new Date();
        const formattedDate = now.toISOString().split('T')[0]; // Format: YYYY-MM-DD
        
        link.setAttribute('download', `users-report-${formattedDate}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);

        dispatch({ type: getReportsSuccess });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getReportsFailure });
    }
}
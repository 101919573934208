import React from "react";
import { Routes as ReactRoutes, Route } from "react-router-dom";
import ProtectedRoute from "../containers/ProtectedRouteContainer";
import { publicationsRoutes } from "../pages/publications/routes";
import { publicationRoutes } from "../pages/publication/routes";
import { dealershipsRoutes } from "../pages/dealerships/routes";
import { settingsRoutes } from "../pages/settings/routes";
import { RoutesProps } from "../interfaces";
import { RouteObject } from "../interfaces/navigation";
import SuspenseLoader from "../components/SuspenseLoader";
import { usersRoutes } from "../pages/Users/routes";
import { dashboardRoutes } from "../pages/Dashboard/routes";
import { leadsRoutes } from "../pages/Leads/routes";
import { notFoundPageRoutes } from "../pages/NotFoundPage/routes";
import { loginRoutes } from "../pages/Login/routes";
import { dashboardItRoutes } from "../pages/DashboardIT/routes";
import { dashboardIntegrationsRoutes } from "../pages/DashboardIntegrations/routes";

const Routes = ({ fetchingRefreshToken, refreshType }: RoutesProps) => {

    const allRoutes: RouteObject[] = [
        ...loginRoutes,
        ...notFoundPageRoutes,
        ...dashboardRoutes,
        ...usersRoutes,
        ...publicationsRoutes,
        ...publicationRoutes,
        ...dealershipsRoutes,
        ...settingsRoutes,
        ...leadsRoutes,
        ...dashboardItRoutes,
        ...dashboardIntegrationsRoutes
    ];

    return (
        <ReactRoutes>
            {allRoutes.map((route: any) => (
                <Route
                    key={route.path}
                    path={route.path}
                    element={
                        <React.Suspense fallback={
                            <SuspenseLoader />
                        }>
                            {
                                (fetchingRefreshToken === "loading" && refreshType === "first_initialization")
                                    ? <SuspenseLoader />
                                    : route.protected
                                        ? <ProtectedRoute>{route.component}</ProtectedRoute>
                                        : route.component
                            }
                        </React.Suspense>
                    }
                >
                    {route?.subRoutes?.map((subRoute: any, index: number) => {
                        return <Route
                            path={subRoute.path}
                            key={"subRoute-" + index}
                            element={subRoute.protected ?
                                <ProtectedRoute>{subRoute.component}</ProtectedRoute>
                                :
                                subRoute.component
                            }>
                            {subRoute?.subRoutes?.map((subSubRoute: any, index: number) => (
                                <Route
                                    path={subSubRoute.path}
                                    key={"subSubRoute-" + index}
                                    element={subSubRoute.component}
                                >
                                    {
                                        subSubRoute?.subRoutes?.map((subSubSubRoute: any) => (
                                            <Route
                                                path={subSubSubRoute.path}
                                                key={"subSubSubRoute-" + index}
                                                element={subSubSubRoute.component}
                                            />
                                        ))
                                    }
                                </Route>
                            ))}
                        </Route>
                    })}
                </Route>
            ))}
            <Route
                path='*'
                element={
                    <React.Suspense fallback={
                        <SuspenseLoader />
                    }>
                        {notFoundPageRoutes[0].component}
                    </React.Suspense>
                }
            />
        </ReactRoutes>
    )
};

export default Routes;
import { Dispatch } from "@reduxjs/toolkit";
import { api } from "../config/api";
import { GetState } from "../config/store";
import { 
    cleanGetUsers as cleanGetUsersReducer,
    addUserRequest,
    addUserSuccess,
    addUserFailure,
    cleanAddUser as cleanAddUserReducer,
    editUserRequest,
    editUserSuccess,
    editUserFailure,
    cleanEditUser as cleanEditUserReducer,
    disabledUserRequest,
    disabledUserSuccess,
    disabledUserFailure,
    cleanDisabledUser as cleanDisabledUserReducer,
    deleteUserRequest,
    deleteUserSuccess,
    deleteUserFailure,
    cleanDeleteUser as cleanDeleteUserReducer,
    getReportsRequest,
    getReportsSuccess,
    getReportsFailure
} from "../reducers/users";
import { UserSidebarProps } from "../interfaces/users";
import { UsersValidTypes } from "../interfaces/types";
import { axiosClient } from "../config/axios";
import { cleanGetPickupPoints as cleanGetPickupPointsReducer, addPickupPointsFailure, addPickupPointsRequest, addPickupPointsSuccess, cleanDeletePickupPoints, deletePickupPointsFailure, deletePickupPointsRequest, deletePickupPointsSuccess, getPickupPointsFailure, getPickupPointsRequest, getPickupPointsSuccess, editPickupPointsRequest, editPickupPointsSuccess, cleanAddPickupPoints } from "../reducers/pickupPoints";

export const getPickupPoints = (data: any) => (dispatch: Dispatch) => _getPickupPoints(data, dispatch);
const _getPickupPoints = async (data: any, dispatch: Dispatch) => {
    try {
        dispatch({ type: getPickupPointsRequest });

        const response = await axiosClient.get(`${api.settings}/inspection-points`);
        console.log(response);
        
        dispatch({ 
            type: getPickupPointsSuccess, 
            payload: {
                pickupPoints: response?.data?.data?.inspection_points,
                total: response?.data?.data?.total
            }
        });

    } catch (error: any) {
        console.log(error);
        dispatch({ type: getPickupPointsFailure, payload: error });
    }
};

export const cleanGetPickupPoints = () => (dispatch: Dispatch) => _cleanGetPickupPoints(dispatch);
const _cleanGetPickupPoints = async (dispatch: Dispatch) => dispatch({type: cleanGetPickupPointsReducer});

export const addPickupPoint = (point: any) => (dispatch: Dispatch) => _addPickupPoint(point, dispatch);
const _addPickupPoint = async (point: any, dispatch: Dispatch) => {
    try {
        dispatch({ type: addPickupPointsRequest });

        const body = JSON.stringify(point);

        const response = await axiosClient.post(`${api.settings}/inspection-points`, body);

        dispatch({ 
            type: addPickupPointsSuccess, 
            payload: response?.data?.data
        });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: addPickupPointsFailure, payload: errorResponse });
    }
};

export const cleanAddPickupPoint = () => (dispatch: Dispatch) => _cleanAddPickupPoint(dispatch);
const _cleanAddPickupPoint = async (dispatch: Dispatch) => dispatch({type: cleanAddPickupPoints});

export const editPickupPoint = (id: string, point: any) => (dispatch: Dispatch) => _editPickupPoint(id, point, dispatch);
const _editPickupPoint = async (id: string, point: any, dispatch: Dispatch) => {
    try {
        dispatch({ type: editPickupPointsRequest });

        const body = JSON.stringify(point);

        const response = await axiosClient.patch(`${api.settings}/inspection-points/${id}`, body);

        dispatch({ type: editPickupPointsSuccess, payload: response?.data?.data });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: editUserFailure, payload: errorResponse });
    }
};

export const cleanEditUser = () => (dispatch: Dispatch) => _cleanEditUser(dispatch);
const _cleanEditUser = async (dispatch: Dispatch) => dispatch({type: cleanEditUserReducer});

export const disabledUser = (id: string, disabled: boolean) => (dispatch: Dispatch) => _disabledUser(id, disabled, dispatch);
const _disabledUser = async (id: string, disabled: boolean, dispatch: Dispatch) => {
    try {
        dispatch({ type: disabledUserRequest });

        const body = JSON.stringify({ disabled: disabled });

        const response = await axiosClient.patch(`${api.users}/${id}`, body);

        dispatch({ 
            type: disabledUserSuccess, 
            payload: response?.data?.data
        });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: disabledUserFailure, payload: errorResponse });
    }
};

export const cleanDisabledUser = () => (dispatch: Dispatch) => _cleanDisabledUser(dispatch);
const _cleanDisabledUser = async (dispatch: Dispatch) => dispatch({type: cleanDisabledUserReducer});

export const deletePickupPoint = (id: string) => (dispatch: Dispatch) => _deletePickupPoint(id, dispatch);
const _deletePickupPoint = async (id: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: deletePickupPointsRequest });

        const response = await axiosClient.delete(`${api.settings}/inspection-points/${id}`);

        dispatch({ type: deletePickupPointsSuccess, payload: response?.data?.data});

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: deletePickupPointsFailure, payload: errorResponse });
    }
};

export const cleanDeletePickupPoint = () => (dispatch: Dispatch) => _cleanDeletePickupPoint(dispatch);
const _cleanDeletePickupPoint = async (dispatch: Dispatch) => dispatch({type: cleanDeletePickupPoints});

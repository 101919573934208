import { useEffect } from 'react';
import styles from './delete.module.scss';
import { DeletedUsersProps } from '../../../../interfaces/components';
import { capitalize } from '../../../../helpers/formatters';
import ButtonShared from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal';
import { useToast } from '../../../../helpers/hooks';

const Delete = ({
    open,
    setOpen,
    userSelected,
    loading,
    pickupPoints,
    deletePickupPoint,
    cleanDeletePickupPoint
}: any) => {
    const toast = useToast();

    const onBack = () => {
        setOpen(false)
    }

    const onDelete = () => {
        deletePickupPoint(userSelected?._id);
    }

    useEffect(() => {
        if (pickupPoints?.delete?.fetching === "failure") {
            toast(`Ha ocurrido un error al eliminar el punto de inspección`);
            cleanDeletePickupPoint();
        }
        if (pickupPoints?.delete?.fetching === "success") {
            toast(`Punto de inspección ${capitalize(userSelected?.name)} eliminado correctamente`);
            setOpen(false)
            cleanDeletePickupPoint();
        }
    }, [pickupPoints?.delete?.fetching]);

    return (
        <Modal open={open} variant="white" className={styles.modal}>
            <p>
                <span className={styles.message}>Estás a punto de eliminar el siguiente punto de inspección</span>
                <span className={styles.name}>{`${capitalize(userSelected?.name)}`}</span>
            </p>
            <div className={styles.buttons}>
                <ButtonShared
                    onPress={onBack}
                    title="Regresar"
                    tertiary
                    size="small"
                    disabled={pickupPoints?.delete?.fetching === "loading"}
                />
                <ButtonShared
                    onPress={onDelete}
                    title="Eliminar"
                    primary
                    size="small"
                    loading={loading}
                />
            </div>
        </Modal>
    )
};
export default Delete;
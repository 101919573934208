import { Dispatch } from "@reduxjs/toolkit";
import { api } from "../config/api";
import { GetState } from "../config/store";
import jwt from "jwt-decode";
import {
    loginRequest,
    loginSuccess,
    loginFailure,
    cleanLogin as cleanLoginReducer,
    logout as logoutReducer,
    refreshTokenRequest,
    refreshTokenSuccess,
    refreshTokenFailure,
    initRefreshType as initRefreshTypeReducer
} from "../reducers/auth";
import { RefreshTokenTypes } from "../interfaces/types";
import { axiosClient } from "../config/axios";
import { UserLoginPostProps } from "../interfaces/auth";
import { catch401 } from "../helpers/functions";
import { datadogRum } from "@datadog/browser-rum";

export const login = (user: UserLoginPostProps) => (dispatch: Dispatch) => _login(user, dispatch);
const _login = async (user: UserLoginPostProps, dispatch: Dispatch) => {
    try {
        dispatch({ type: loginRequest });

        const response = await axiosClient.post(`${api.users}/access`, user);

        // @ts-ignore
        const { exp } = jwt(response?.data.data.token);

        sessionStorage.setItem("userSession",
            JSON.stringify({
                user: response?.data?.data.user,
                token: response?.data?.data.token,
                refreshToken: response?.data?.data.refresh_token,
                expirationTime: exp,
            })
        );

        datadogRum.setUser({
            id: response?.data?.data?.user?._id,
            name: response?.data?.data?.user?.name,
            email: response?.data?.data?.user?.email,
            profile: response?.data?.data?.user?.profile?.name
        });

        dispatch({type: loginSuccess, payload: {
            user: response?.data?.data.user,
            token: response?.data?.data.token,
            refreshToken: response?.data?.data.refresh_token,
            expirationTime: exp,
        }});

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        if (errorResponse?.errors) return dispatch({ type: loginFailure, payload: errorResponse.errors });
        if (errorResponse?.code) return dispatch({ type: loginFailure, payload: errorResponse.code });
        if (errorResponse?.message) return dispatch({ type: loginFailure, payload: errorResponse.message });
        dispatch({ type: loginFailure, payload: "Error de conexión." });
    }
};

export const cleanLogin = () => (dispatch: Dispatch) => _cleanLogin(dispatch);
const _cleanLogin = async (dispatch: Dispatch) => dispatch({type: cleanLoginReducer});

export const logout = () => (dispatch: Dispatch) => _logout(dispatch);
const _logout = async (dispatch: Dispatch) => {
    try {
        dispatch({ type: logoutReducer });

        sessionStorage.removeItem("userSession")
    } catch (err: any) {
        console.log(err);
    }
};

export const refreshToken = (refreshType?: RefreshTokenTypes) => (dispatch: Dispatch, getState: GetState) => _refreshToken(refreshType, dispatch, getState);
const _refreshToken = async (refreshType: RefreshTokenTypes = "", dispatch: Dispatch, getState: GetState) => {
    try {
        const refreshToken = getState().auth.refreshToken;
        if (!refreshToken) return;

        dispatch({ type: refreshTokenRequest, payload: refreshType });

        const response = await fetch(process.env.REACT_APP_API_TOKEN || "", {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${refreshToken}`,
            },
        });

        const json = await response.json();
        if (!json.success) throw json;

        const { data: { token, refresh_token, user } } = json;
        const { exp }: any = jwt(token);

        datadogRum.setUser({
            id: user?._id,
            name: user?.name,
            email: user?.email,
            profile: user?.profile?.name
        });

        dispatch({ 
            type: refreshTokenSuccess, 
            payload: { 
                token: token, 
                refreshToken: refresh_token, 
                expirationTime: exp, 
                refreshType: refreshType,
                user: user
            } 
        });

    } catch (err: any) {
        console.log(err);
        catch401(err.error.statusCode,dispatch)
        dispatch({ type: refreshTokenFailure, payload: { err } });
    }
};

export const initRefreshType = () => (dispatch: Dispatch) => _initRefreshType(dispatch);
const _initRefreshType = async (dispatch: Dispatch) => dispatch({type: initRefreshTypeReducer});
import { createSlice } from "@reduxjs/toolkit";
import { PublicationStateProps } from "../interfaces/publication";
import { getOfferAccepted } from "../actions/publication";

const initialState: PublicationStateProps = {
    fetching: "unknown",
    data: [],
    error: "",
    articles: {
        fetching: "unknown",
        data: [],
        error: ""
    },
    put: {
        fetching: "unknown",
        error: ""
    },
    pause: {
        fetching: "unknown",
        paused: false,
        error: ""
    },
    offers: {
        fetching: "unknown",
        data: [],
        error: ""
    },
    offerAccepted: null,
    fetchingOfferAccepted: "unknown",
    fetchingDeletePublication: 'unknown'
};

const publicationSlice = createSlice({
    name: "publication",
    initialState,
    reducers: {
        getPublicationRequest: (state) => {
            state.fetching = "loading";
        },
        getPublicationSuccess: (state, action) => {
            state.fetching = "success";
            state.data = action.payload;
        },
        getPublicationFailure: (state, action) => {
            state.fetching = "failure";
            state.error = action.payload;
        },
        cleanGetPublication: (state) => {
            state.fetching = initialState.fetching;
        },
        getPublicationArticlesRequest: (state) => {
            state.articles.fetching = "loading";
        },
        getPublicationArticlesSuccess: (state, action) => {
            state.articles.fetching = "success";
            state.articles.data = action.payload;
        },
        getPublicationArticlesFailure: (state, action) => {
            state.articles.fetching = "failure";
            state.articles.error = action.payload;
        },
        cleanGetPublicationArticles: (state) => {
            state.articles.fetching = initialState.articles.fetching;
        },
        putStatusPublicationRequest: (state, action) => {
            state.put.fetching = "loading";
        },
        putStatusPublicationSuccess: (state, action) => {
            state.put.fetching = "success";
            state.data = action.payload;
        },
        putStatusPublicationFailure: (state, action) => {
            state.put.fetching = "failure";
            state.put.error = action.payload;
        },
        cleanPutStatusPublication: (state) => {
            state.put.fetching = initialState.put.fetching;
        },
        pausePublicationRequest: (state) => {
            state.pause.fetching = "loading";
        },
        pausePublicationSuccess: (state, action) => {
            state.pause.fetching = "success";
            state.pause.paused = action.payload;
        },
        pausePublicationFailure: (state, action) => {
            state.pause.fetching = "failure";
            state.pause.error = action.payload;
        },
        cleanPausePublication: (state) => {
            state.pause.fetching = initialState.pause.fetching;
        },
        getOffersRequest: (state) => {
            state.offers.fetching = "loading";
        },
        getOffersSuccess: (state, action) => {
            state.offers.fetching = "success";
            state.offers.data = action.payload;
        },
        getOffersFailure: (state, action) => {
            state.offers.fetching = "failure";
            state.offers.error = action.payload;
        },
        cleanGetOffers: (state) => {
            state.offers.fetching = initialState.offers.fetching;
        },
        getOfferAcceptedRequest: (state, action) => {
            state.fetchingOfferAccepted = "loading"
        },
        getOfferAcceptedSuccess: (state, action) => {
            state.offerAccepted = action.payload;
            state.fetchingOfferAccepted = "success"
        },
        getOfferAcceptedFailure: (state, action) => {
            state.fetchingOfferAccepted = "failure"
        },
        deletePublicationRequest: (state, action) => {
            state.fetchingDeletePublication = "loading"
        },
        deletePublicationSuccess: (state, action) => {
            state.fetchingDeletePublication = "success"

        },
        deletePublicationFailure: (state, action) => {
            state.fetchingDeletePublication = "failure"

        },
        cleanDeletePublication: (state, action) => {
            state.fetchingDeletePublication = initialState.fetchingDeletePublication
            state.fetchingOfferAccepted = initialState.fetchingOfferAccepted
            state.offerAccepted = initialState.offerAccepted;
        }

    },
});

export const {
    getPublicationRequest,
    getPublicationSuccess,
    getPublicationFailure,
    cleanGetPublication,
    getPublicationArticlesRequest,
    getPublicationArticlesSuccess,
    getPublicationArticlesFailure,
    cleanGetPublicationArticles,
    putStatusPublicationRequest,
    putStatusPublicationSuccess,
    putStatusPublicationFailure,
    cleanPutStatusPublication,
    pausePublicationRequest,
    pausePublicationSuccess,
    pausePublicationFailure,
    cleanPausePublication,
    getOffersRequest,
    getOffersSuccess,
    getOffersFailure,
    cleanGetOffers,
    getOfferAcceptedRequest,
    getOfferAcceptedSuccess,
    getOfferAcceptedFailure,
    deletePublicationRequest,
    deletePublicationSuccess,
    deletePublicationFailure,
    cleanDeletePublication
} =
    publicationSlice.actions;

export default publicationSlice.reducer;

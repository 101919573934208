import { useEffect, useMemo, useState } from 'react';
import styles from './sidebar.module.scss';
import { SidebarUsersProps } from '../../../../interfaces/components';
import { useToast } from '../../../../helpers/hooks';
import { capitalize, formatRut, validateRut } from '../../../../helpers/formatters';
import ButtonShared from '../../../../components/Button/Button';
import SidebarShared from '../../../../components/Sidebar/Sidebar';
import SelectShared from '../../../../components/Select/Select';
import TextFieldShared from '../../../../components/TextField/TextField';
import PhoneInput from '../../../../components/PhoneInput/PhoneInput';
import { CIVIL_STATUSES, GENDER_STATUSES, PROFILES } from '../../../../helpers/constants';
import DatePickerShared from '../../../../components/DatePicker/DatePicker';
import moment from 'moment';

const Sidebar = ({
    user,
    state,
    setState,
    userSelected,
    addPickupPoint,
    cleanAddPickupPoint,
    pickupPoints,
    editPickupPoint,
    cleanEditPickupPoint,
    getDealerships, 
    dealerships,
    cleanGetDealerships,
    getBranchs,
    branchs,
    cleanGetBranchs
}: any) => {
    const toast = useToast();
    const [name, setName] = useState<any>({value: "", error: ""});
    const [lastName, setLastName] = useState<any>({value: "", error: ""});
    const [rut, setRut] = useState<any>({value: "", error: ""});
    const [email, setEmail] = useState<any>({value: "", error: ""});
    const [password, setPassword] = useState<any>({
        value: "", 
        error: "", 
        validation: {
            specialCaracter: false,
            mayus: false,
            correctLength: false,
            number: false
        }
    });
    const [phoneValue, setPhoneValue] = useState<any>({code: "", number: "", error: ""});
    const [birthday, setBirthday] = useState<any>({value: "", error: ""});
    const [civilSelected, setCivilSelected] = useState<any>({value: "", error: ""});
    const [genderSelected, setGenderSelected] = useState<any>({value: "", error: ""});
    const [profileSelected, setProfileSelected] = useState<any>({value: "", error: ""});
    const [dealershipSelected, setDealershipSelected] = useState<any>({value: "", error: ""});
    const [branchSelected, setBranchSelected] = useState<any>({value: "", error: ""});

    const handleChangePhone = (value: any, type: string) => {
        if(type === "code"){
            setPhoneValue({...phoneValue, code: value});
        }else{
            setPhoneValue({...phoneValue, number: value});
        }
    }

    const onSubmit = () => {

        if(!name?.value) {return setName({...name, error: "Debes ingresar un nombre"})} else setName({...name, error: ""});
        let bodyRequest: any = {
            "name": name?.value,
        };

        if(state.type === "add") addPickupPoint(bodyRequest);
        if(state.type === "edit") editPickupPoint(userSelected?._id, bodyRequest);
    }

    const handleChangeDealership = (value: string) => {
        setDealershipSelected({...dealershipSelected, value: value});
        if(profileSelected?.value === "admin-branch" || profileSelected?.value === "seller"){
            getBranchs(value);
        }
    }

    const handleChangeRut = (value: any) => {
        const formatValue = formatRut(value);
        if(!validateRut(formatValue)){
            return setRut({value: formatValue, error: "Ingresa un RUT válido"});
        }
        setRut({value: formatValue, error: ""});
    }

    const civilNormalized: any = useMemo(() => {
        return CIVIL_STATUSES && CIVIL_STATUSES.map((item: any, index: number) => (
            {
                id: item?.id,
                name: item?.label
            }
        ))
    }, [CIVIL_STATUSES]);

    const genderNormalized: any = useMemo(() => {
        return GENDER_STATUSES && GENDER_STATUSES.map((item: any, index: number) => (
            {
                id: index,
                name: item
            }
        ))
    }, [GENDER_STATUSES]);

    const profilesNormalized: any = useMemo(() => {
        return PROFILES && PROFILES.map((item: any, index: number) => (
            {
                id: item?.id,
                name: item?.label
            }
        ))
    }, [PROFILES]);

    const dealershipsNormalized: any = useMemo(() => {
        return dealerships?.data && dealerships?.data.map((item: any) => (
            {
                id: item?._id,
                name: item?.name,
                address: item?.address
            }
        ))
    }, [dealerships?.data]);

    const branchsNormalized: any = useMemo(() => {
        return branchs?.data && branchs?.data.map((item: any) => (
            {
                id: item?._id,
                name: item?.name
            }
        ))
    }, [branchs?.data]);

    const showDealerships = useMemo(() => {
        return (profileSelected?.value === "admin-dealership" || profileSelected?.value === "admin-branch" || profileSelected?.value === "seller") ? true : false;
    }, [profileSelected?.value]);

    const showBranchs = useMemo(() => {
        return (profileSelected?.value === "admin-branch" || profileSelected?.value === "seller") ? true : false;
    }, [profileSelected?.value]);

    useEffect(() => {
        if(branchs?.fetching === "failure"){
            toast("Ha ocurrido un error al traer las sucursales");
            cleanGetBranchs();
        }
        if(branchs?.fetching === "success"){
            cleanGetBranchs();
        }
    }, [branchs?.fetching]);

    useEffect(() => {
        if(dealerships?.fetching === "failure"){
            toast("Ha ocurrido un error al traer las concesionarias");
            cleanGetDealerships();
        }
        if(dealerships?.fetching === "success"){
            cleanGetDealerships();
        }
    }, [dealerships?.fetching]);

    useEffect(() => {
        if(state.open){
            if(userSelected?._id){
                setName({value: userSelected?.name, error: ""});
                setLastName({value: userSelected?.last_name, error: ""});
                setRut({value: formatRut(userSelected?.legal_id), error: ""});
                setPhoneValue({code: userSelected?.phone?.code, number: userSelected?.phone?.number, error: ""});
                setEmail({value: userSelected?.email, error: ""});
                setPassword({
                    value: "", 
                    error: "", 
                    validation: {
                        specialCaracter: false,
                        mayus: false,
                        correctLength: false,
                        number: false
                    }
                });
                setBirthday({value: userSelected?.date_of_birth, error: ""});
                setCivilSelected({value: userSelected?.civil_status, error: ""});
                setGenderSelected({value: userSelected?.gender, error: ""});
                setProfileSelected({value: userSelected?.profile?.code ?? "", error: ""});
            }else{
                setName({value: "", error: ""});
                setLastName({value: "", error: ""});
                setRut({value: "", error: ""});
                setPhoneValue({code: "+56", number: "", error: ""});
                setEmail({value: "", error: ""});
                setPassword({
                    value: "", 
                    error: "", 
                    validation: {
                        specialCaracter: false,
                        mayus: false,
                        correctLength: false,
                        number: false
                    }
                });
                setBirthday({value: moment().toDate(), error: ""});
                setCivilSelected({value: "", error: ""});
                setGenderSelected({value: "", error: ""});
                setProfileSelected({value: "", error: ""});
                setDealershipSelected({value: "", error: ""});
                setBranchSelected({value: "", error: ""});
            }
        }
    }, [userSelected]);

    useEffect(() => {
        if(pickupPoints?.add?.fetching === "failure"){
            cleanAddPickupPoint();
            switch(pickupPoints?.add?.error){
                default:
                    toast("Ha ocurrido un error al añadir un nuevo punto de inspección");
                    break;
            }
        }
        if(pickupPoints?.add?.fetching === "success"){
            toast("Punto de inspección añadido correctamente");
            setState({...state, open: false});
            cleanAddPickupPoint();
        }
    }, [pickupPoints?.add?.fetching]);

    useEffect(() => {
        if(pickupPoints?.edit?.fetching === "failure"){
            toast("Ha ocurrido un error al editar el usuario");
            cleanEditPickupPoint();
        }
        if(pickupPoints?.edit?.fetching === "success"){
            toast("Punto de inspección editado correctamente");
            setState({...state, open: false});
            cleanEditPickupPoint();
        }
    }, [pickupPoints?.edit?.fetching]);

    useEffect(() => {
        setState(state);
    }, [state]);

    return (
        <SidebarShared 
            variant="close" 
            open={state.open} 
            title={`${state.type === "add" ? "Nuevo" : state.type === "edit" ? "Editar": "Eliminar"} punto de inspeccion`} 
            onBack={() => setState({...state, open: false})} 
            id="usersSidebarContent"
        >
            <div className={styles.sidebarContent}>
                <div className={styles.inputs}>
                    <TextFieldShared
                        label="Nombre"
                        name="name"
                        value={name?.value}
                        onChange={(value: any) => setName({...name, value: value})}
                        error={name?.error}
                        className={`${styles.name} ${styles.full}`}
                    />
                </div>
                <ButtonShared
                    onPress={onSubmit}
                    title={
                        state.type === "add" 
                        ? "Crear punto de inspección" 
                        : "Guardar cambios"
                    }
                    className={styles.sendInvitation}
                    primary
                    loading={pickupPoints?.add?.fetching === "loading" || pickupPoints?.edit?.fetching === "loading"}
                />
            </div>
        </SidebarShared>
    )
};
export default Sidebar;
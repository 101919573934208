import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { PickupPointsContainerProps } from "../../../../interfaces/containers";
import Delete from "../../../../pages/settings/PickupPoints/Delete";
import { deletePickupPoint, cleanDeletePickupPoint } from "../../../../actions/pickupPoints";

function mapStateToProps(state: any) {
    return {
        pickupPoints: state.pickupPoints
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        deletePickupPoint,     
        cleanDeletePickupPoint
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Delete);
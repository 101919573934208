import styles from './informationLoader.module.scss';
import { Skeleton } from "@mui/material";

const InformationLoader = () => {

    return (
        <div className={styles.skeletonContainer}>
            <div className={styles.data}>
                <Skeleton animation="wave" variant="text" className={styles.name} />
                <Skeleton animation="wave" variant="text" className={styles.description} />
                <Skeleton animation="wave" variant="text" className={styles.actualPrice} />
                <Skeleton animation="wave" variant="text" className={styles.oldPrice} />
            </div>
            {/* <div className={styles.lineResponsive}></div>
            <Skeleton animation="wave" variant="text" className={`${styles.button} ${styles.cash}`} />
            <Skeleton animation="wave" variant="text" className={`${styles.button} ${styles.credit}`} /> */}
        </div>
    )
}

export default InformationLoader;
import ReactDOM from 'react-dom/client';
import './index.module.scss';
import './styles/themeColors.scss';
import Root from './components/Root';
import { store } from "./config/store";
import { datadogRum } from '@datadog/browser-rum';
import { dev } from './config/api';

if(!dev){
  datadogRum.init({
    applicationId: 'b7ed0f38-2fcc-48b6-98b9-e28ae3cbe1a1',
    clientToken: 'pubbe3a058ed92378655a9de39251ff29b8',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'linze-manager',
    env: process.env.REACT_APP_NODE_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if(window?.Cypress){
  window.store = store;
}

root.render(<Root/>);
import { Dispatch } from "@reduxjs/toolkit";
import { api } from "../config/api";
import { GetState } from "../config/store";
import { 
    getDealershipsRequest,
    getDealershipsSuccess,
    getDealershipsFailure,
    cleanGetDealerships as cleanGetDealershipsReducer,
    addDealershipRequest,
    addDealershipSuccess,
    addDealershipFailure,
    cleanAddDealership as cleanAddDealershipReducer,
    editDealershipRequest,
    editDealershipSuccess,
    editDealershipFailure,
    cleanEditDealership as cleanEditDealershipReducer,
    deleteDealershipRequest,
    deleteDealershipSuccess,
    deleteDealershipFailure,
    cleanDeleteDealership as cleanDeleteDealershipReducer
} from "../reducers/dealerships";
import { DealershipSidebarProps } from "../interfaces/dealerships";
import { axiosClient } from "../config/axios";

export const getDealerships = (page?: number, limit?: number) => (dispatch: Dispatch) => _getDealerships(page, limit, dispatch);
const _getDealerships = async (page = 1, limit = 10, dispatch: Dispatch) => {
    try {
        dispatch({ type: getDealershipsRequest });

        const response = await axiosClient.get(`${api.users}/dealerships?page=${page}&limit=${limit}`);

        dispatch({ 
            type: getDealershipsSuccess, 
            payload: {
                dealerships: response?.data?.data?.dealerships,
                total: response?.data?.data?.total
            }
        });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getDealershipsFailure, payload: errorResponse });
    }
};

export const cleanGetDealerships = () => (dispatch: Dispatch) => _cleanGetDealerships(dispatch);
const _cleanGetDealerships = async (dispatch: Dispatch) => dispatch({type: cleanGetDealershipsReducer});

export const addDealership = (dealership: DealershipSidebarProps) => (dispatch: Dispatch) => _addDealership(dealership, dispatch);
const _addDealership = async (dealership: DealershipSidebarProps, dispatch: Dispatch) => {
    try {
        dispatch({ type: addDealershipRequest });

        const body = JSON.stringify(dealership);

        const response = await axiosClient.post(`${api.users}/dealerships`, body);

        dispatch({ 
            type: addDealershipSuccess, 
            payload: response?.data?.data
        });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: addDealershipFailure, payload: errorResponse });
    }
};

export const cleanAddDealership = () => (dispatch: Dispatch) => _cleanAddDealership(dispatch);
const _cleanAddDealership = async (dispatch: Dispatch) => dispatch({type: cleanAddDealershipReducer});

export const editDealership = (id: string, dealership: DealershipSidebarProps) => (dispatch: Dispatch) => _editDealership(id, dealership, dispatch);
const _editDealership = async (id: string, dealership: DealershipSidebarProps, dispatch: Dispatch) => {
    try {
        dispatch({ type: editDealershipRequest });

        const body = JSON.stringify(dealership);

        const response = await axiosClient.put(`${api.users}/dealerships/${id}`, body);

        dispatch({ 
            type: editDealershipSuccess, 
            payload: response?.data?.data
        });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: editDealershipFailure, payload: errorResponse });
    }
};

export const cleanEditDealership = () => (dispatch: Dispatch) => _cleanEditDealership(dispatch);
const _cleanEditDealership = async (dispatch: Dispatch) => dispatch({type: cleanEditDealershipReducer});

export const deleteDealership = (id: string) => (dispatch: Dispatch) => _deleteDealership(id, dispatch);
const _deleteDealership = async (id: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: deleteDealershipRequest });

        const response = await axiosClient.delete(`${api.users}/deaerships/${id}`);

        dispatch({ 
            type: deleteDealershipSuccess, 
            payload: response?.data?.data
        });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: deleteDealershipFailure, payload: errorResponse });
    }
};

export const cleanDeleteDealership = () => (dispatch: Dispatch) => _cleanDeleteDealership(dispatch);
const _cleanDeleteDealership = async (dispatch: Dispatch) => dispatch({type: cleanDeleteDealershipReducer});
import { createSlice } from "@reduxjs/toolkit";
import { UsersStateProps } from "../interfaces/users";

const initialState: UsersStateProps = {
    list: {
        fetching: "unknown",
        data: [],
        total: 0,
        error: ""
    },
    add: {
        fetching: "unknown",
        data: [],
        error: ""
    },
    edit: {
        fetching: "unknown",
        data: [],
        error: ""
    },
    disabled: {
        fetching: "unknown",
        error: ""
    },
    delete: {
        fetching: "unknown",
        error: ""
    },
    reports: {
        fetching: "unknown",
        data: [],
        error: ""
    }
};

const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        getUsersRequest: (state) => {
            state.list.fetching = "loading";
        },
        getUsersSuccess: (state, action) => {
            state.list.fetching = "success";
            state.list.data = action.payload.users;
            state.list.total = action.payload.total;
        },
        getUsersFailure: (state, action) => {
            state.list.fetching = "failure";
            state.list.error = action.payload;
        },
        cleanGetUsers: (state) => {
            state.list.fetching = initialState.list.fetching;
        },
        addUserRequest: (state) => {
            state.add.fetching = "loading";
        },
        addUserSuccess: (state, action) => {
            state.add.fetching = "success";
            state.add.data = action.payload.users;
        },
        addUserFailure: (state, action) => {
            state.add.fetching = "failure";
            state.add.error = action.payload;
        },
        cleanAddUser: (state) => {
            state.add.fetching = initialState.add.fetching;
        },
        editUserRequest: (state) => {
            state.edit.fetching = "loading";
        },
        editUserSuccess: (state, action) => {
            state.edit.fetching = "success";
            state.edit.data = action.payload.users;
        },
        editUserFailure: (state, action) => {
            state.edit.fetching = "failure";
            state.edit.error = action.payload;
        },
        cleanEditUser: (state) => {
            state.edit.fetching = initialState.edit.fetching;
        },
        disabledUserRequest: (state) => {
            state.disabled.fetching = "loading";
        },
        disabledUserSuccess: (state) => {
            state.disabled.fetching = "success";
        },
        disabledUserFailure: (state, action) => {
            state.disabled.fetching = "failure";
            state.disabled.error = action.payload.code;
        },
        cleanDisabledUser: (state) => {
            state.disabled.fetching = initialState.disabled.fetching;
        },
        deleteUserRequest: (state) => {
            state.delete.fetching = "loading";
        },
        deleteUserSuccess: (state, action) => {
            state.delete.fetching = "success";
        },
        deleteUserFailure: (state, action) => {
            state.delete.fetching = "failure";
            state.delete.error = action.payload;
        },
        cleanDeleteUser: (state) => {
            state.delete.fetching = initialState.delete.fetching;
        },
        getReportsRequest: (state) => {
            state.reports.fetching = "loading";
        },
        getReportsSuccess: (state, action) => {
            state.reports.fetching = "success";
            state.reports.data = action.payload;
        },
        getReportsFailure: (state, action) => {
            state.reports.fetching = "failure";
            state.reports.error = action.payload;
        },
        cleanGetReports: (state) => {
            state.reports.fetching = initialState.reports.fetching;
        }
    },
});

export const {
    getUsersRequest,
    getUsersSuccess,
    getUsersFailure,
    cleanGetUsers,
    addUserRequest,
    addUserSuccess,
    addUserFailure,
    cleanAddUser,
    editUserRequest,
    editUserSuccess,
    editUserFailure,
    cleanEditUser,
    disabledUserRequest,
    disabledUserSuccess,
    disabledUserFailure,
    cleanDisabledUser,
    deleteUserRequest,
    deleteUserSuccess,
    deleteUserFailure,
    cleanDeleteUser,
    getReportsRequest,
    getReportsSuccess,
    getReportsFailure,
    cleanGetReports
} =
    usersSlice.actions;

export default usersSlice.reducer;

import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import EntryPoint from "../../../../pages/settings/PickupPoints/Sidebar";
import { UsersContainerProps } from "../../../../interfaces/containers";
import { addUser, cleanAddUser, editUser, cleanEditUser } from "../../../../actions/users";
import { getDealerships, cleanGetDealerships } from "../../../../actions/dealerships";
import { getBranchs, cleanGetBranchs } from "../../../../actions/branchs";
import { getPickupPoints, cleanGetPickupPoints, addPickupPoint, cleanAddPickupPoint, editPickupPoint } from "../../../../actions/pickupPoints";

function mapStateToProps(state: any) {
    return{
        pickupPoints: state.pickupPoints
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        addUser, 
        cleanAddUser, 
        editUser, 
        cleanEditUser,
        getDealerships,
        cleanGetDealerships,
        getBranchs,
        cleanGetBranchs,
        addPickupPoint,
        cleanGetPickupPoints,
        cleanAddPickupPoint,
        editPickupPoint
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(EntryPoint);
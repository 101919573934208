export const CIVIL_STATUSES = [
    {
        id: "married",
        label: "Casado/a"
    },
    {
        id: "single",
        label: "Soltero"
    },
    {
        id: "widowed",
        label: "Viudo/a"
    },
    {
        id: "divorced",
        label: "Divorciado/a"
    },
    {
        id: "other",
        label: "Otro"
    }
]

export const PROFILES = [
    {
        id: "agent",
        label: "Agente"
    },
    {
        id: "final-user",
        label: "Usuario final"
    },
    {
        id: "admin-dealership",
        label: "Admin concesionaria"
    },
    {
        id: "admin-branch",
        label: "Admin sucursal"
    },
    {
        id: "seller",
        label: "Vendedor"
    }
]

export const GENDER_STATUSES = [
    "Masculino",
    "Femenino",
    "Otro"
]

export const DEALERSHIPS_TYPES = [
    {
        id: "new",
        label: "Nuevos"
    },
    {
        id: "used",
        label: "Usados"
    }
]
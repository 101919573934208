import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import EntryPoint from "../../../pages/settings/PickupPoints";
import { PickupPointsContainerProps } from "../../../interfaces/containers";
import { cleanGetPickupPoints, getPickupPoints } from "../../../actions/pickupPoints";

function mapStateToProps(state: PickupPointsContainerProps) {
    return{
        pickupPoints: state.pickupPoints
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getPickupPoints,
        cleanGetPickupPoints,
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(EntryPoint);
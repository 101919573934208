import { AnyAction } from "redux";
import { REHYDRATE } from "redux-persist";
import {  refreshTokenSuccess } from "../../reducers/auth";
import { initRefreshType, refreshToken } from "../../actions/auth";

export default function userMiddleware(store: any) {
    return (next: any) => async (action: AnyAction) => {
        const result = next(action);
        if (action.type === REHYDRATE && store.getState().auth?.fetchingRefreshToken !== "loading") {
            refreshToken("first_initialization")(store.dispatch, store.getState);
        }
        if (action.type === refreshTokenSuccess) {
            if(store.getState().auth?.refreshType === "first_initialization"){
                initRefreshType()(store.dispatch);
            }
        }
        return result;
    }
};
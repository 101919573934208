import { images } from '../../constants/images';
import styles from './suspenseLoader.module.scss';
import { LinearProgress } from "@mui/material";

const SuspenseLoader = () => {
    return (
        <div className={styles.suspenseLoader}>
            <img src={images.logoIsotipo} className={styles.logo} alt="logo" />
            <LinearProgress
                className={styles.loader}
                classes={{
                    barColorPrimary: styles.barColor
                }}
            />
        </div>
    );
};

export default SuspenseLoader;
import React from "react";
import { RouteObject } from "../../interfaces/navigation";

const EntryPoint = React.lazy(() => import("../../containers/Dashboard/EntryPointContainer"));

export const dashboardRoutes: RouteObject[] = [
    {
        title: "dashboard",
        path: "/dashboard",
        component: <EntryPoint />,
        protected: true
    }
];
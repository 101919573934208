import { CircularProgress } from "@mui/material";
import { LoaderProps } from "../../interfaces/components";
import styles from "./loader.module.scss"

const Loader = ({
    text, 
    className
}: LoaderProps ) => {
    return(
        <div className={`${styles.loader} ${className}`} id="loader">
            <CircularProgress 
                size={styles.loader}
                classes={{
                    colorPrimary: styles.loader
                }}
            />
            <p className={styles.text}>{text}</p>
        </div>
    )
}

export default Loader;
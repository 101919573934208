import { createSlice } from "@reduxjs/toolkit";
import { PublicationsStateProps } from "../interfaces/publications";

const initialState: PublicationsStateProps = {
    fetching: "unknown",
    data: [],
    total: 0,
    error: "",
    sellers: {
        fetching: "unknown",
        data: [],
        error: ""
    },
    status: {
        fetching: "unknown",
        data: [],
        error: ""
    },
    filters: {
        text: "",
        status: [],
        years: [],
        sellers: []
    },
    reports: {
        fetching: "unknown",
        data: [],
        error: ""
    }
};

const publicationsSlice = createSlice({
    name: "publications",
    initialState,
    reducers: {
        getPublicationsByStatusRequest: (state) => {
            state.fetching = "loading";
        },
        getPublicationsByStatusSuccess: (state, action) => {
            state.fetching = "success";
            state.data = action.payload.publications;
            state.total = action.payload.total;
        },
        getPublicationsByStatusFailure: (state, action) => {
            state.fetching = "failure";
            state.error = action.payload;
        },
        cleanGetPublicationsByStatus: (state) => {
            state.fetching = initialState.fetching;
        },
        getSellersRequest: (state) => {
            state.sellers.fetching = "loading";
        },
        getSellersSuccess: (state, action) => {
            state.sellers.fetching = "success";
            state.sellers.data = action.payload;
        },
        getSellersFailure: (state, action) => {
            state.sellers.fetching = "failure";
            state.sellers.error = action.payload;
        },
        cleanGetSellers: (state) => {
            state.sellers.fetching = initialState.sellers.fetching;
        },
        getStatusRequest: (state) => {
            state.status.fetching = "loading";
        },
        getStatusSuccess: (state, action) => {
            state.status.fetching = "success";
            state.status.data = action.payload;
        },
        getStatusFailure: (state, action) => {
            state.status.fetching = "failure";
            state.status.error = action.payload;
        },
        cleanGetStatus: (state) => {
            state.status.fetching = initialState.status.fetching;
        },
        setSearch: (state, action) => {
            state.filters.text = action.payload;
        },
        setStatus: (state, action) => {
            state.filters.status = action.payload;
        },
        setYears: (state, action) => {
            state.filters.years = action.payload;
        },
        setSellers: (state, action) => {
            state.filters.sellers = action.payload;
        },
        getReportsRequest: (state) => {
            state.reports.fetching = "loading";
        },
        getReportsSuccess: (state, action) => {
            state.reports.fetching = "success";
            state.reports.data = action.payload;
        },
        getReportsFailure: (state, action) => {
            state.reports.fetching = "failure";
            state.reports.error = action.payload;
        },
        cleanGetReports: (state) => {
            state.reports.fetching = initialState.reports.fetching;
        }
    },
});

export const {
    getPublicationsByStatusRequest,
    getPublicationsByStatusSuccess,
    getPublicationsByStatusFailure,
    cleanGetPublicationsByStatus,
    getSellersRequest,
    getSellersSuccess,
    getSellersFailure,
    cleanGetSellers,
    getStatusRequest,
    getStatusSuccess,
    getStatusFailure,
    cleanGetStatus,
    setSearch,
    setStatus,
    setYears,
    setSellers,
    getReportsRequest,
    getReportsSuccess,
    getReportsFailure,
    cleanGetReports
} =
    publicationsSlice.actions;

export default publicationsSlice.reducer;

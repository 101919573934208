import styles from './app.module.scss';
import Toast from "../Toast/Toast";
import { useLocation } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import NavBar from '../../containers/NavBar/NavBarContainer';
import Routes from '../../containers/RoutesContainer';
import { AppProps } from '../../interfaces/app';
import SideBar from '../../containers/SideBar/SideBarContainer';
import RefreshToken from '../../containers/RefreshToken/RefreshTokenContainer';

const App = ({
	user
}: AppProps) => {
	const location = useLocation();

	return (
		<SnackbarProvider content={(key, message) => <Toast id={key} content={message} />}>
			<RefreshToken />
			<div className={styles.app}>
				{
					user && location.pathname !== "/" && <NavBar />
				}
				<div className={styles.appContent}>
					{
						(user && location.pathname !== "/") && <SideBar />
					}
					<Routes />
				</div>
			</div>
		</SnackbarProvider>
	);
}

export default App;

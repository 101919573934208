import { api } from "../config/api";
import { axiosClient } from "../config/axios";
import { CoordsProps } from '../interfaces/index';

export const getPlaces = (text: string = "", coords: CoordsProps) => () =>  _getPlaces(text, coords);
export const _getPlaces = async (text: string, coords: CoordsProps) => {
    try {
        const response = await axiosClient.get(`https://maps.googleapis.com/maps/api/place/textsearch/json?location=${coords.lat},${coords.long}&radius=10000&query=${text}&key=${api.mapsGoogle}`);


        return response?.data?.results;
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        return errorResponse?.code;
    }
};
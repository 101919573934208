import styles from './pageCard.module.scss';
import { PageCardProps } from '../../interfaces/components';

const PageCard = ({
    children,
    className
}: PageCardProps) => {

    return (
        <section className={`${styles.pageCard} ${className}`}>
            {children}
        </section>
    );
};

export default PageCard;
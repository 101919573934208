import { createSlice } from "@reduxjs/toolkit";
import { UsersStateProps } from "../interfaces/users";

const initialState: UsersStateProps = {
    list: {
        fetching: "unknown",
        data: [],
        total: 0,
        error: ""
    },
    add: {
        fetching: "unknown",
        data: [],
        error: ""
    },
    edit: {
        fetching: "unknown",
        data: [],
        error: ""
    },
    disabled: {
        fetching: "unknown",
        error: ""
    },
    delete: {
        fetching: "unknown",
        error: ""
    },
    reports: {
        fetching: "unknown",
        data: [],
        error: ""
    }
};

const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        getPickupPointsRequest: (state) => {
            state.list.fetching = "loading";
        },
        getPickupPointsSuccess: (state, action) => {
            state.list.fetching = "success";
            state.list.data = action.payload.pickupPoints;
            state.list.total = action.payload.total;
        },
        getPickupPointsFailure: (state, action) => {
            state.list.fetching = "failure";
            state.list.error = action.payload;
        },
        cleanGetPickupPoints: (state) => {
            state.list.fetching = initialState.list.fetching;
        },
        addPickupPointsRequest: (state) => {
            state.add.fetching = "loading";
        },
        addPickupPointsSuccess: (state, action) => {
            state.add.fetching = "success";
            state.add.data = action.payload.pickupPoints;
        },
        addPickupPointsFailure: (state, action) => {
            state.add.fetching = "failure";
            state.add.error = action.payload;
        },
        cleanAddPickupPoints: (state) => {
            state.add.fetching = initialState.add.fetching;
        },
        editPickupPointsRequest: (state) => {
            state.edit.fetching = "loading";
        },
        editPickupPointsSuccess: (state, action) => {
            state.edit.fetching = "success";
            state.edit.data = action.payload.pickupPoints;
        },
        editPickupPointsFailure: (state, action) => {
            state.edit.fetching = "failure";
            state.edit.error = action.payload;
        },
        cleanEditPickupPoints: (state) => {
            state.edit.fetching = initialState.edit.fetching;
        },
        disabledPickupPointsRequest: (state) => {
            state.disabled.fetching = "loading";
        },
        disabledPickupPointsSuccess: (state) => {
            state.disabled.fetching = "success";
        },
        disabledPickupPointsFailure: (state, action) => {
            state.disabled.fetching = "failure";
            state.disabled.error = action.payload.code;
        },
        cleanDisabledPickupPoints: (state) => {
            state.disabled.fetching = initialState.disabled.fetching;
        },
        deletePickupPointsRequest: (state) => {
            state.delete.fetching = "loading";
        },
        deletePickupPointsSuccess: (state) => {
            state.delete.fetching = "success";
        },
        deletePickupPointsFailure: (state, action) => {
            state.delete.fetching = "failure";
            state.delete.error = action.payload;
        },
        cleanDeletePickupPoints: (state) => {
            state.delete.fetching = initialState.delete.fetching;
        }
    },
});

export const {
    getPickupPointsRequest,
    getPickupPointsSuccess,
    getPickupPointsFailure,
    cleanGetPickupPoints,
    addPickupPointsRequest,
    addPickupPointsSuccess,
    addPickupPointsFailure,
    cleanAddPickupPoints,
    editPickupPointsRequest,
    editPickupPointsSuccess,
    editPickupPointsFailure,
    cleanEditPickupPoints,
    disabledPickupPointsRequest,
    disabledPickupPointsSuccess,
    disabledPickupPointsFailure,
    cleanDisabledPickupPoints,
    deletePickupPointsRequest,
    deletePickupPointsSuccess,
    deletePickupPointsFailure,
    cleanDeletePickupPoints
} =
    usersSlice.actions;

export default usersSlice.reducer;

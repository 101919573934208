import React from "react";
import { RouteObject } from "../../interfaces/navigation";

const EntryPoint = React.lazy(() => import("../../containers/NotFoundPage/NotFoundPageContainer"));

export const notFoundPageRoutes: RouteObject[] = [
    {
        title: "notFound",
        path: "/notFound",
        component: <EntryPoint />,
        protected: true
    }
];
import React from "react";
import { RouteObject } from "../../interfaces/navigation";

const EntryPoint = React.lazy(() => import("../../containers/Login/LoginContainer"));

export const loginRoutes: RouteObject[] = [
    {
        title: "Login",
        path: "/",
        component: <EntryPoint/>
    }
];
import { Navigate, useLocation } from "react-router-dom";
import { UserProps } from "../interfaces/auth";

export const ProtectedRoute = ({ user, children }: { user: UserProps | null, children: JSX.Element }) => {
	let location = useLocation();

	if (!user) {
		return <Navigate to="/" state={{ from: location }} replace />;
	}else{
		return children;
	}
};
import { useEffect, useState } from 'react';
import styles from './offersModal.module.scss';
import { OffersModalPublicationProps } from '../../../interfaces/components';
import { useToast } from '../../../helpers/hooks';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Modal from '../../../components/Modal';
import Pagination from '../../../components/Pagination';
import { addPointsInText } from '../../../helpers/formatters';
import { translations } from '../../../constants/translactions';
import moment from 'moment';
import Loader from '../../../components/Loader';
import NoDataList from '../../../components/NoDataList';
import { images } from '../../../constants/images';

const OffersModal = ({
    open, 
    onClose,
    offers,
    cleanGetOffers,
    id
}: OffersModalPublicationProps) => {
    const toast = useToast();

    useEffect(() => {
        if(offers?.fetching === "failure"){
            toast("Ha ocurrido un error al obtener las ofertas");
            cleanGetOffers();
            onClose();
        }
        if(offers?.fetching === "success"){
            cleanGetOffers();
        }
    }, [offers?.fetching]);

    return (
        <Modal open={open} onClose={onClose} variant="close" className={styles.modal}>
            <div className={styles.container}>
                {
                    offers?.fetching === "loading"
                    ?
                        <Loader text="Cargando ofertas" className={styles.loader} />
                    :
                    offers?.data && offers?.data?.length === 0
                        ?
                            <div className={styles.noData}>
                                <NoDataList
                                    title="Esta publicación no tiene ofertas"
                                    text=""
                                    image={images.noData}
                                />
                            </div>
                        :
                        <TableContainer className={styles.table}>
                            <Table>
                                <TableHead>
                                    <TableRow className={styles.head}>
                                        <TableCell className={styles.cell}>Fecha</TableCell>
                                        <TableCell className={styles.cell}>Oferta</TableCell>
                                        <TableCell className={styles.cell}>Usuario</TableCell>
                                        <TableCell className={styles.cell}>Email</TableCell>
                                        <TableCell className={styles.cell}>Telefono</TableCell>
                                        <TableCell className={styles.cell}>Estado</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        offers?.data && offers?.data.length > 0 && offers?.data?.map((item: any, index: number) => {
                                            let stateCode = "";
                                            switch(item?.status){
                                                case "payment":
                                                case "delivered":
                                                case "sold_out":
                                                    stateCode = styles.green
                                                    break;
                                                case "rejected":
                                                case "cancelled":
                                                    stateCode = styles.red
                                                    break;
                                                case "inspection":
                                                case "documentation_signature":
                                                case "pending":
                                                    stateCode = styles.gray
                                                    break;
                                                default:
                                                    stateCode = styles.blue
                                                    break;
                                            }
                                            return <TableRow key={index} className={styles.row}>
                                                <TableCell className={styles.cell}>
                                                    <p className={styles.text}>{moment(item?.date).format("DD MMM YY HH:mm")}</p>
                                                </TableCell>
                                                <TableCell className={styles.cell}>
                                                    <p className={styles.text}>${addPointsInText(item?.offer ?? 0)}</p>
                                                </TableCell>
                                                <TableCell className={styles.cell}>
                                                    <p className={styles.text}>{item?.buyer_brief?.name}</p>
                                                </TableCell>
                                                <TableCell className={`${styles.cell} ${styles.bold}`}>
                                                    <p className={styles.text}>{item?.buyer_brief?.email}</p>
                                                </TableCell>
                                                <TableCell className={`${styles.cell} ${styles.bold}`}>
                                                    <p className={styles.text}>{`${item?.buyer_brief?.phone?.code ?? ""} ${item?.buyer_brief?.phone?.number ?? ""}`}</p>
                                                </TableCell>
                                                <TableCell className={styles.cell}>
                                                    <div className={`${styles.text} ${styles.state} ${stateCode}`}>{translations(item?.status).toUpperCase()}</div>
                                                </TableCell>
                                            </TableRow>
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                }
            </div>
        </Modal>
    )
};
export default OffersModal;
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { CoordsProps } from "../interfaces";
import { _getPlaces } from "../actions/places";

export const useToast = () => {
    const { enqueueSnackbar } = useSnackbar();
    const pushToast = (
        message: string,
        secondParameter?: any
    ) => {
        let content = `message\n${message}`;

        if(secondParameter){
            if(typeof(secondParameter) === "string"){
                content = `title\n${secondParameter}\n${message}`;
            }
        }

        enqueueSnackbar(content, { autoHideDuration: 4000 });
    };

    return pushToast;
};

export const useOnGetPlaces = () => {
    const [places, setPlaces] = useState<any>({
        fetching: "unknown",
        data: []
    });

    const getPlaces = async (text: string, coords: CoordsProps) => {
        const placesResult = await new Promise((resolve, reject) => {
            setPlaces({...places, fetching: "loading"});
            _getPlaces(text, coords)
            .then((res: any) => resolve(res))
            .catch((reason: any) => reject(reason));
        });
        setPlaces({fetching: "success", data: placesResult ?? []});
    }
    
    return {
        getPlaces,
        places
    }
}

export const useDebounce = (value: any, delay: number) => {
    const [debounceValue, setDebounceValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebounceValue(value)
        }, delay);
        return () => {
            clearTimeout(handler)
        }

    }, [value, delay]);
    return debounceValue;
}
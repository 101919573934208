import { getYears } from "../helpers/formatters";
import { images } from "./images";

export const YEARS = getYears();

export const TRANSMISSIONS = [
    "Mecánica", 
    "Automático"
];

export const CONFORTS = [
    {
        name: "Climatizador automático",
        codeName: "air_filter",
        image: images.airFilter,
        selected: false
    },
    {
        name: "Velocidad crucero",
        codeName: "cruice_speed",
        image: images.cruiceSpeed,
        selected: false
    },
    {
        name: "Cristales eléctricos",
        codeName: "electric_crystals",
        image: images.electricCrystals,
        selected: false
    },
    {
        name: "Sensor de estacionamiento",
        codeName: "parking_sensor",
        image: images.parkingSensor,
        selected: false
    },
    {
        name: "Computadora a bordo",
        codeName: "on_board_computer",
        image: images.onBoardComputer,
        selected: false
    },
    {
        name: "Sistema de audio HI-Fi",
        codeName: "audio_hifi",
        image: images.audioHifi,
        selected: false
    },
    {
        name: "Conectividad BT/Wifi",
        codeName: "bt_wifi",
        image: images.bluetoothWifi,
        selected: false
    },
    {
        name: "Asientos de cuero",
        codeName: "leather_seats",
        image: images.leatherSeats,
        selected: false
    }
];

export const CARACTERISTICS = [
    {
        image: images.carType,
        title: "tipo",
        type: "type"
    },
    {
        image: images.color,
        title: "color",
        type: "color"
    },
    {
        image: images.transmission,
        title: "transmisión",
        type: "transmission"
    },
    {
        image: images.fuel,
        title: "combustible",
        type: "fuel"
    }
]
import { Dispatch } from "@reduxjs/toolkit";
import { api } from "../config/api";
import { GetState } from "../config/store";
import {
    getPublicationRequest,
    getPublicationSuccess,
    getPublicationFailure,
    cleanGetPublication as cleanGetPublicationReducer,
    getPublicationArticlesRequest,
    getPublicationArticlesSuccess,
    getPublicationArticlesFailure,
    cleanGetPublicationArticles as cleanGetPublicationArticlesReducer,
    putStatusPublicationRequest,
    putStatusPublicationSuccess,
    putStatusPublicationFailure,
    cleanPutStatusPublication as cleanPutStatusPublicationReducer,
    pausePublicationRequest,
    pausePublicationSuccess,
    pausePublicationFailure,
    cleanPausePublication as cleanPausePublicationReducer,
    getOffersRequest,
    getOffersSuccess,
    getOffersFailure,
    cleanGetOffers as cleanGetOffersReducer,
    getOfferAcceptedRequest,
    getOfferAcceptedSuccess,
    getOfferAcceptedFailure,
    deletePublicationRequest,
    deletePublicationSuccess,
    deletePublicationFailure,
    cleanDeletePublication as cleanDeletePublicationReducer
} from "../reducers/publication";
import { StatusPublication } from "../interfaces/types";
import { axiosClient } from "../config/axios";

export const getPublication = (id: string) => (dispatch: Dispatch) => _getPublication(id, dispatch);
const _getPublication = async (id: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: getPublicationRequest });

        const response = await axiosClient.get(`${api.publications}/${id}?with_pictures=true`);

        dispatch({ type: getPublicationSuccess, payload: response?.data.data.publication });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getPublicationFailure, payload: errorResponse });
    }
}

export const cleanGetPublication = () => (dispatch: Dispatch) => _cleanGetPublication(dispatch);
const _cleanGetPublication = (dispatch: Dispatch) => dispatch({ type: cleanGetPublicationReducer });

export const getPublicationArticles = (id: string) => (dispatch: Dispatch) => _getPublicationArticles(id, dispatch);
const _getPublicationArticles = async (id: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: getPublicationArticlesRequest });

        const response = await axiosClient.get(`${api.publications}/${id}/articles`);

        dispatch({ type: getPublicationArticlesSuccess, payload: response?.data.data.article });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getPublicationArticlesFailure, payload: errorResponse });
    }
}

export const cleanGetPublicationArticles = () => (dispatch: Dispatch) => _cleanGetPublicationArticles(dispatch);
const _cleanGetPublicationArticles = (dispatch: Dispatch) => dispatch({ type: cleanGetPublicationArticlesReducer });

export const cleanDeletePublication = () => (dispatch: Dispatch) => _cleanDeletePublication(dispatch);
const _cleanDeletePublication = (dispatch: Dispatch) => dispatch({ type: cleanDeletePublicationReducer });

export const putStatusPublication = (id: string, status: StatusPublication) => (dispatch: Dispatch) => _putStatusPublication(id, status, dispatch);
const _putStatusPublication = async (id: string, status: StatusPublication, dispatch: Dispatch) => {
    try {
        dispatch({ type: putStatusPublicationRequest });

        const body = JSON.stringify({ status: status });

        const response = await axiosClient.patch(`${api.publications}/${id}/status`, body);

        dispatch({ type: putStatusPublicationSuccess, payload: response?.data.data.publication });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: putStatusPublicationFailure, payload: errorResponse });
    }
}

export const cleanPutStatusPublication = () => (dispatch: Dispatch) => _cleanPutStatusPublication(dispatch);
const _cleanPutStatusPublication = (dispatch: Dispatch) => dispatch({ type: cleanPutStatusPublicationReducer });

export const pausePublication = (id: string, pause: boolean) => (dispatch: Dispatch) => _pausePublication(id, pause, dispatch);
const _pausePublication = async (id: string, pause: boolean, dispatch: Dispatch) => {
    try {
        dispatch({ type: pausePublicationRequest });

        const body = JSON.stringify({ paused: pause });

        const response = await axiosClient.patch(`${api.publications}/${id}/toggle-paused`, body);

        dispatch({ type: pausePublicationSuccess, payload: response?.data.data.publication.paused });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error?.code;
        console.log(errorResponse);
        dispatch({ type: pausePublicationFailure, payload: errorResponse });
    }
}

export const cleanPausePublication = () => (dispatch: Dispatch) => _cleanPausePublication(dispatch);
const _cleanPausePublication = (dispatch: Dispatch) => dispatch({ type: cleanPausePublicationReducer });

export const getOffers = (id: string) => (dispatch: Dispatch) => _getOffers(id, dispatch);
const _getOffers = async (id: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: getOffersRequest });

        const response = await axiosClient.get(`${api.publications}/offers/publication/${id}`);

        dispatch({ type: getOffersSuccess, payload: response?.data.data.offers });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getOffersFailure, payload: errorResponse });
    }
}

export const cleanGetOffers = () => (dispatch: Dispatch) => _cleanGetOffers(dispatch);
const _cleanGetOffers = (dispatch: Dispatch) => dispatch({ type: cleanGetOffersReducer });

export const getOfferAccepted = (id: string) => (dispatch: Dispatch) => _getOfferAccepted(id, dispatch);
const _getOfferAccepted = async (id: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: getOfferAcceptedRequest });

        const response = await axiosClient.get(`${api.publications}/${id}/offers/status?status=accepted`);

        dispatch({ type: getOfferAcceptedSuccess, payload: response?.data.data.offer });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getOfferAcceptedFailure, payload: errorResponse });
    }
}
export const deletePublication = (id: string) => (dispatch: Dispatch) => _deletePublication(id, dispatch);
const _deletePublication = async (id: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: deletePublicationRequest });

        const response = await axiosClient.delete(`${api.publications}/${id}`);

        dispatch({ type: deletePublicationSuccess });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: deletePublicationFailure, payload: errorResponse });
    }
}
import { createSlice } from "@reduxjs/toolkit";
import { TermsStateProps } from "../interfaces/terms";

const initialState: TermsStateProps = {
    fetching: "unknown",
    data: [],
    error: "",
    update: {
        fetching: "unknown",
        error: ""
    }
};

const termsSlice = createSlice({
    name: "terms",
    initialState,
    reducers: {
        getTermsRequest: (state) => {
            state.fetching = "loading";
        },
        getTermsSuccess: (state, action) => {
            state.fetching = "success";
            state.data = action.payload;
        },
        getTermsFailure: (state, action) => {
            state.fetching = "failure";
            state.error = action.payload;
        },
        cleanGetTerms: (state) => {
            state.fetching = initialState.fetching;
        },
        updateTermsRequest: (state) => {
            state.update.fetching = "loading";
        },
        updateTermsSuccess: (state, action) => {
            state.update.fetching = "success";
            state.data = action.payload;
        },
        updateTermsFailure: (state, action) => {
            state.update.fetching = "failure";
            state.update.error = action.payload;
        },
        cleanUpdateTerms: (state) => {
            state.update.fetching = initialState.update.fetching;
        }
    },
});

export const {
    getTermsRequest,
    getTermsSuccess,
    getTermsFailure,
    cleanGetTerms,
    updateTermsRequest,
    updateTermsSuccess,
    updateTermsFailure,
    cleanUpdateTerms
} =
    termsSlice.actions;

export default termsSlice.reducer;

import React from "react";
import { RouteObject } from "../../interfaces/navigation";

const EntryPoint = React.lazy(() => import("../../containers/DashboardIt/EntryPointContainer"));

export const dashboardItRoutes: RouteObject[] = [
    {
        title: "dashboardIT",
        path: "/dashboardIt",
        component: <EntryPoint />,
        protected: true
    }
];
import styles from './caracteristicsLoader.module.scss';
import { Skeleton } from "@mui/material";
import Caracteristic from '../../../Caracteristic';

const CaracteristicsLoader = () => {

    return (
        <div className={styles.skeletonContainer}>
            <Skeleton animation="wave" variant="text" className={styles.title} />
            <div className={styles.content}>
                <Caracteristic loading />
                <Caracteristic loading />
                <Caracteristic loading />
                <Caracteristic loading />
            </div>
        </div>
    )
}

export default CaracteristicsLoader;
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from "../reducers/auth";
import dealershipsReducer from "../reducers/dealerships";
import usersReducer from "../reducers/users";
import publicationsReducer from "../reducers/publications";
import publicationReducer from "../reducers/publication";
import branchsReducer from "../reducers/branchs";
import termsReducer from "../reducers/terms";
import additionalCostsReducer from "../reducers/additionalCosts";
import paymentsReducer from "../reducers/payments";
import leadsReducer from "../reducers/leads";
import pickupPointsReducer from "../reducers/pickupPoints";
import userMiddleware from "./middlewares/userMiddleware";
import { dev } from "./api";
import unauthorizedReducer, { unauthorized } from "../reducers/unauthorized";

const middlewares = !dev ? [thunk, userMiddleware] : [thunk, logger, userMiddleware];

const persistAuth = {
    key: 'auth',
    storage: storage,
};

const persistPublications = {
    key: 'publications',
    storage: storage,
};

const persistedAuthReducer = persistReducer(persistAuth, authReducer);
const persistedPublicationsReducer = persistReducer(persistPublications, publicationsReducer);

const appReducer = combineReducers({
    auth: persistedAuthReducer,
    dealerships: dealershipsReducer,
    users: usersReducer,
    publications: persistedPublicationsReducer,
    publication: publicationReducer,
    branchs: branchsReducer,
    terms: termsReducer,
    additionalCosts: additionalCostsReducer,
    payments: paymentsReducer,
    leads: leadsReducer,
    unauthorized: unauthorizedReducer,
    pickupPoints: pickupPointsReducer
});

const rootReducer = (state: any, action:any) => {
    if (action.type === unauthorized) {
        // Resetea todo el estado, excepto los reducers persistidos
        const { auth, colors, ...rest } = state;
        state = undefined;
        console.log("entra aca")
        localStorage.clear()
        window.location.href = "/";
        setTimeout(() => {
            window.location.reload();
        },300)
    }
    return appReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
    middleware: middlewares,
});

export const persistor = persistStore(store);
export type StoreState = typeof store;
export type PersitorStoreState = typeof persistor;
export type GetState = typeof store.getState;

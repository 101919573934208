import { createSlice } from "@reduxjs/toolkit";
import { initialStateLeadsProps } from "../interfaces/leads";

const initialState: initialStateLeadsProps = {
    leads: [],
    fetching: "unknown"
}

const leadsSlice = createSlice({
    name: 'leads',
    initialState,
    reducers: {
        getLeadsRequest: (state, action) => {
            state.fetching = action.payload === "reloading" || action.payload === "filter" ? "reloading" : "loading";
        },
        getLeadsSuccess: (state, action) => {
            state.fetching = "success";
            state.leads = action.payload;
        },
        getLeadsFailure: (state) => {
            state.fetching = "failure";
        },
        cleanLeads: (state) => {
            state.fetching = initialState.fetching;
        }
    }
});

export const {
    getLeadsRequest,
    getLeadsSuccess,
    getLeadsFailure,
    cleanLeads
} = leadsSlice.actions;

export default leadsSlice.reducer;
import { Dispatch } from "@reduxjs/toolkit";
import { api } from "../config/api";
import { GetState } from "../config/store";
import { 
    getPaymentsRequest, 
    getPaymentsSuccess,
    getPaymentsFailure, 
    cleanGetPayments as cleanGetPaymentsReducer,
    updatePaymentsRequest, 
    updatePaymentsSuccess,
    updatePaymentsFailure, 
    cleanUpdatePayments as cleanUpdatePaymentsReducer
} from "../reducers/payments";
import { axiosClient } from "../config/axios";

export const getPayments = () => (dispatch: Dispatch) => _getPayments(dispatch);
const _getPayments = async (dispatch: Dispatch) => {
    try {
        dispatch({ type: getPaymentsRequest });

        const response = await axiosClient.get(`${api.settings}/payment-instruction`);

        dispatch({ 
            type: getPaymentsSuccess, 
            payload: response?.data.data.payment_instruction[0]
        });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getPaymentsFailure, payload: errorResponse });
    }
};

export const cleanGetPayments = () => (dispatch: Dispatch) => _cleanGetPayments(dispatch);
const _cleanGetPayments = async (dispatch: Dispatch) => dispatch({ type: cleanGetPaymentsReducer });

export const updatePayments = (id: string, body: object) => (dispatch: Dispatch) => _updatePayments(id, body, dispatch);
const _updatePayments = async (id: string, body: object, dispatch: Dispatch) => {
    try {
        dispatch({ type: updatePaymentsRequest });

        const bodyReq = JSON.stringify(body);

        const response = await axiosClient.put(`${api.settings}/payment-instruction/${id}`, bodyReq);

        dispatch({ 
            type: updatePaymentsSuccess, 
            payload: response?.data?.data?.payment_instruction
        });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: updatePaymentsFailure, payload: errorResponse });
    }
};

export const cleanUpdatePayments = () => (dispatch: Dispatch) => _cleanUpdatePayments(dispatch);
const _cleanUpdatePayments = async (dispatch: Dispatch) => dispatch({ type: cleanUpdatePaymentsReducer });
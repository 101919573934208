import { createSlice } from "@reduxjs/toolkit";

export interface initialStateUnauthorizedProps {
    error: string;
}

const initialState: initialStateUnauthorizedProps = {
    error: ""
}

const unauthorizedSlice = createSlice({
    name: 'unauthorized',
    initialState,
    reducers: {
        unauthorized: (state, action) => {
            state.error = action.payload;
        }
    }
});

export const { unauthorized } = unauthorizedSlice.actions;

export default unauthorizedSlice.reducer;
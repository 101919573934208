import { Dispatch } from 'redux';
import {
    getLeadsSuccess,
    getLeadsFailure,
    getLeadsRequest,
    cleanLeads as cleanLeadsReducer
} from '../reducers/leads';
import { axiosClient } from '../config/axios';
import { api } from '../config/api';
import { unauthorized } from '../reducers/unauthorized';
import { GetState } from '../config/store';
import { testMerge } from '../helpers/functions';

export const getLeads = (withLoading?: string, endDate?: string, startDate?: string, stringQuery?: any) => (dispatch: Dispatch, getState: GetState) => _getLeads(withLoading, endDate, startDate, stringQuery, dispatch, getState);
const _getLeads = async (withLoading = "", startDate = "", endDate = "", stringQuery = "", dispatch: Dispatch, getState: GetState) => {
    try {
        dispatch({ type: getLeadsRequest, payload: withLoading });
        const searchParams = new URLSearchParams(stringQuery);

        const response = await axiosClient.get(`${api.leads}?startDate=${startDate}&endDate=${endDate}&` + searchParams);

        const parsedInfo = {
            "opportunity": {
                ...response.data.data.opportunity,
                //@ts-ignore
                "leads": testMerge((getState().leads?.leads?.opportunity?.leads || []), response.data.data.opportunity.leads),
            },
            "register": {
                ...response.data.data.register,
                //@ts-ignore
                "leads": testMerge((getState().leads?.leads?.register?.leads || []), response.data.data.register.leads),

            },
            "offer": {
                ...response.data.data.offer,
                //@ts-ignore
                "leads": testMerge((getState().leads?.leads?.offer?.leads || []), response.data.data.offer.leads),

            },
            "scheduling": {
                ...response.data.data.scheduling,
                //@ts-ignore
                "leads": testMerge((getState().leads?.leads?.scheduling?.leads || []), response.data.data.scheduling.leads),

            },
            "match": {
                ...response.data.data.match,
                //@ts-ignore
                "leads": testMerge((getState().leads?.leads?.match?.leads || []), response.data.data.match.leads),

            },
            "sold": {
                ...response.data.data.sold,
                //@ts-ignore
                "leads": testMerge((getState().leads?.leads?.sold?.leads || []), response.data.data.sold.leads),

            },
            "not_interested": {
                ...response.data.data.not_interested,
                //@ts-ignore
                "leads": testMerge((getState().leads?.leads?.not_interested?.leads || []), response.data.data.not_interested.leads),

            }
        }


        dispatch({ type: getLeadsSuccess, payload: withLoading !== 'reloading' ? response.data.data : parsedInfo });

    } catch (error: any) {
        console.log("getLeadserror", error.status)
        const errorResponse = error?.response?.data?.error;
        if (errorResponse?.statusCode === 403 || errorResponse?.statusCode === 401 || error?.response?.status === 403 || error?.response?.status === 401) {
            dispatch({ type: unauthorized });
            return;
        }
        dispatch({ type: getLeadsFailure });
    }
};

export const cleanLeads = () => (dispatch: Dispatch) => _cleanLeads(dispatch);
const _cleanLeads = async (dispatch: Dispatch) => dispatch({ type: cleanLeadsReducer });
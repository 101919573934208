import { memo } from "react";
import { IconButton } from '@mui/material';
import styles from "./pagination.module.scss";
import { images } from "../../constants/images";
import { PaginationProps } from '../../interfaces/components'
import Icon from '@mdi/react';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';

const Pagination = ({
    page,
    setPage,
    className,
    total
}: PaginationProps) => {
    const firstPage = () => {
        setPage(1);
    }

    const prevPage = () => {
        if(page > 1) setPage(page - 1);
    }

    const nextPage = () => {
        if(page < total) setPage(page + 1);
    }

    const lastPage = () => {
        setPage(total);
    }
    
    return (
        <>
        {
            !(page === 1 && total === 1) &&
            <div className={`${styles.pagination} ${className}`}>
                <IconButton className={`${styles.button} ${styles.doubleArrow}`} aria-label='first page' onClick={firstPage}>
                    <Icon path={mdiChevronLeft} className={styles.icon} />
                    <Icon path={mdiChevronLeft} className={styles.icon} />
                </IconButton>
                <IconButton className={styles.button} aria-label='Anterior pagina' onClick={prevPage}>
                    <Icon path={mdiChevronLeft} className={styles.icon} />
                </IconButton>
                <p className={styles.text}>{page} de {total}</p>
                <IconButton className={styles.button} aria-label='Siguiente pagina' onClick={nextPage}>
                    <Icon path={mdiChevronRight} className={styles.icon} />
                </IconButton>
                <IconButton className={`${styles.button} ${styles.doubleArrow}`} aria-label='last page' onClick={lastPage}>
                    <Icon path={mdiChevronRight} className={styles.icon} />
                    <Icon path={mdiChevronRight} className={styles.icon} />
                </IconButton>
            </div>
        }
        </>
    )
}

export default memo(Pagination);
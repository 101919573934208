import { memo, useEffect, useState } from "react";
import styles from "./entryPoint.module.scss";
import { images } from "../../../constants/images";
import { PickupPointsPageProps } from "../../../interfaces/pages";
import PageCard from "../../../components/PageCard/PageCard";
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import NoDataList from "../../../components/NoDataList/NoDataList";
import Loader from "../../../components/Loader/Loader";
import { useToast } from "../../../helpers/hooks";
import { capitalize, formatRut } from "../../../helpers/formatters";
import ButtonShared from "../../../components/Button/Button";
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import Pagination from "../../../components/Pagination/Pagination";
import Sidebar from "../../../containers/Settings/PickupPoints/sidebarContainer/SidebarContainer";
import Disabled from "../../../containers/Users/DisabledContainer/DisabledContainer";
import Delete from "../../../containers/Settings/PickupPoints/DeleteContainer/DeleteContainer";
import { translations } from "../../../constants/translactions";
import DeleteIcon from '@mui/icons-material/Delete';
import TextFieldShared from "../../../components/TextField/TextField";

const PickupPoints = ({
    getPickupPoints,
    pickupPoints,
    cleanGetPickupPoints,
}: PickupPointsPageProps) => {
    const toast = useToast();
    const [page, setPage] = useState<number>(1);
    const [usersAux, setUsersAux] = useState<any>([]);
    const [search, setSearch] = useState<any>("");
    const [totalUsers, setTotalUsers] = useState<number>(1);
    const [loading, setLoading] = useState<any>([]);
    const [sidebarState, setSidebarState] = useState<any>({ open: false, type: "add" });
    const [userSelected, setUserSelected] = useState<any>([]);
    const [openModalDisabled, setOpenModalDisabled] = useState<boolean>(false);
    const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);

    const onAddUser = () => {
        setUserSelected({});
        setSidebarState({ open: true, type: "add" });
    }

    const onEditUser = (item: any) => {
        setUserSelected(item);
        setSidebarState({ open: true, type: "edit" });
    }

    const onDisabledUser = (item: any) => {
        setUserSelected(item);
        setOpenModalDisabled(true)
    }

    const onDeleteUser = (item: any) => {
        setUserSelected(item);
        setOpenModalDelete(true)
    };
    useEffect(() => {
        if (pickupPoints?.list?.fetching === "loading" && usersAux?.length > 0) {
            setLoading(true);
        }
        if (pickupPoints?.list?.fetching === "failure") {
            setLoading(false);
            toast("Ha ocurrido un error al cargar los puntos de recogida");
            cleanGetPickupPoints();
        }
        if (pickupPoints?.list?.fetching === "success") {
            setUsersAux(pickupPoints?.list?.data);
            cleanGetPickupPoints();
            setLoading(false);
        }
        if (pickupPoints?.list?.total) {
            setTotalUsers(Math.ceil(pickupPoints?.list?.total / 10));
        }
    }, [pickupPoints?.list?.fetching]);

    useEffect(() => {
        if (pickupPoints?.add?.fetching === "success" || pickupPoints?.edit?.fetching === "success" || pickupPoints?.disabled?.fetching === "success" || pickupPoints?.delete?.fetching === "success") {
            getPickupPoints({ page });
        }
    }, [pickupPoints?.add?.fetching, pickupPoints?.edit?.fetching, pickupPoints?.disabled?.fetching, pickupPoints?.delete?.fetching]);

    useEffect(() => {
        getPickupPoints({ page, search });
    }, [page]);

    return (
        <>
            <Sidebar
                state={sidebarState}
                setState={setSidebarState}
                userSelected={userSelected}
            />
            <Disabled
                open={openModalDisabled}
                setOpen={setOpenModalDisabled}
                userSelected={userSelected}
                loading={pickupPoints?.disabled?.fetching === "loading"}
            />
            <Delete
                open={openModalDelete}
                setOpen={setOpenModalDelete}
                userSelected={userSelected}
                loading={pickupPoints?.delete?.fetching === "loading"}
            />
            <PageCard>
                <div className={styles.container}>
                    {
                        (pickupPoints?.list?.fetching === "loading" && usersAux?.length === 0)
                            ? <Loader text="Buscando puntos de inspección" className={styles.loader} />
                            :
                            <div className={styles.content}>
                                <div className={styles.header}>
                                    <ButtonShared
                                        onPress={onAddUser}
                                        title="Añadir"
                                        icon={<AddIcon className={styles.iconButton} />}
                                        primary
                                    />
                                </div>
                                <div id="usersList" className={styles.list}>
                                    {
                                        usersAux && usersAux.length === 0
                                            ?
                                            <div className={styles.noData}>
                                                <NoDataList
                                                    title="No encontramos resultados para tu búsqueda"
                                                    text="Inténtalo de nuevo o prueba utilizando otros filtros"
                                                    image={images.noData}
                                                />
                                            </div>
                                            :
                                            <TableContainer className={`${styles.table} ${loading && styles.loading}`}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow className={styles.head}>
                                                            <TableCell className={styles.cell}>Direccion</TableCell>
                                                            <TableCell className={styles.cell}></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            usersAux && usersAux.length > 0 && usersAux?.map((item: any, index: number) => (
                                                                <TableRow key={index} className={styles.row}>
                                                                    <TableCell className={`${styles.cell} ${styles.bold} ${styles.ellipsis}`}>
                                                                        <p className={styles.text}>{`${capitalize(item?.name)}`}</p>
                                                                    </TableCell>
                                                                    <TableCell className={styles.cell}>
                                                                        <div className={styles.action}>
                                                                            {/* <IconButton title="Editar" onClick={() => onEditUser(item)} disabled={loading}>
                                                                                <CreateIcon className={styles.icon} />
                                                                            </IconButton> */}
                                                                            <IconButton title="Eliminar" onClick={() => onDeleteUser(item)} disabled={loading}>
                                                                                <DeleteIcon className={styles.icon} />
                                                                            </IconButton>
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                    }
                                </div>
                                <Pagination
                                    page={page}
                                    setPage={setPage}
                                    className={styles.pagination}
                                    total={totalUsers}
                                />
                            </div>
                    }
                </div>
            </PageCard>
        </>
    )
}

export default memo(PickupPoints);
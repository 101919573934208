import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { AuthContainerProps } from "../../interfaces/containers";
import sideBar from "../../Layout/SideBar";
import { logout } from "../../actions/auth";

function mapStateToProps(state: AuthContainerProps) {
    return{
        
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        logout
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(sideBar);
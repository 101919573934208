import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "../../config/store";
import App from "../../containers/App/AppContainer";
import { injectStore } from "../../config/axios";
injectStore(store);

const Root = () => (
    <Provider store={store}>
		<PersistGate persistor={persistor}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</PersistGate>
	</Provider>
)

export default Root;
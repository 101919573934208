import { useEffect } from 'react';
import styles from './delete.module.scss';
import { DeletePublicationProps } from '../../../interfaces/components';
import ButtonShared from '../../../components/Button/Button';
import Modal from '../../../components/Modal';
import { useToast } from '../../../helpers/hooks';

const Delete = ({
    open,
    setOpen,
    onDelete,
    publicationSelected
}: DeletePublicationProps) => {
    const toast = useToast();

    const onBack = () => {
        setOpen(false)
    }
    
    return (
        <Modal open={open} variant="white" className={styles.modal}>
            <p>
                <span className={styles.message}>¿Seguro que quieres eliminar esta publicación?</span>
                <span className={styles.name}>{`${publicationSelected?.primary_description} ${publicationSelected?.title}`}</span>
            </p>
            <div className={styles.buttons}>
                <ButtonShared 
                    onPress={onBack}
                    title="Regresar" 
                    tertiary
                    size="small"
                />
                <ButtonShared 
                    onPress={()=>onDelete(publicationSelected?._id)}
                    title={"Eliminar"}
                    primary
                    size="small"
                />
            </div>
        </Modal>
    )
};
export default Delete;
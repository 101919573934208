import { memo, useEffect, useState } from "react";
import styles from "./entryPoint.module.scss";
import { PaymentsPageProps } from "../../../interfaces/pages";
import PageCard from "../../../components/PageCard/PageCard";
import ButtonShared from "../../../components/Button/Button";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import TextFieldShared from "../../../components/TextField/TextField";
import { useToast } from "../../../helpers/hooks";
import { Divider } from "@mui/material";
import { formatRut, validateRut } from "../../../helpers/formatters";

const Payments = ({
    payments,
    getPayments,
    cleanGetPayments,
    updatePayments,
    cleanUpdatePayments
}: PaymentsPageProps) => {
    const toast = useToast();
    const [valeVista, setValeVista] = useState<any>({
		type: {
			value: "",
            error: ""
		},
		mount: {
			value: "",
            error: ""
		}
	});
    const [beneficiary, setBeneficiary] = useState<any>({
		name: {
			value: "",
            error: ""
		},
		rut: {
			value: "",
            error: ""
		}
	});

    const handleChangeRut = (value: any) => {
        const formatValue = formatRut(value);
        if(!validateRut(formatValue)){
            return setBeneficiary({...beneficiary, rut: {value: formatValue, error: "Ingresa un rut válido"}});
        }
        setBeneficiary({...beneficiary, rut: {...beneficiary.rut, value: formatValue}});
    }
    
    const onSavePayments = () => {
        if(!valeVista?.type?.value) return setValeVista({...valeVista, type: {...valeVista.type, error: "Debes ingresar tipo"}});
        if(!valeVista?.mount?.value) return setValeVista({...valeVista, mount: {...valeVista.mount, error: "Debes ingresar monto"}});
        if(!beneficiary?.name?.value) return setBeneficiary({...beneficiary, name: {...beneficiary.name, error: "Debes ingresar nombre"}});
        if(!beneficiary?.rut?.value) return setBeneficiary({...beneficiary, name: {...beneficiary.name, error: "Debes ingresar RUT"}});

        
        if(!validateRut(beneficiary?.rut?.value)){
            return setBeneficiary({...beneficiary, name: {...beneficiary.name, error: "RUT incorrecto"}});
        }

        let body = {
            beneficiary_name: beneficiary?.name?.value,
            rut: beneficiary?.rut?.value,
            type: valeVista?.type?.value,
            mount: valeVista?.mount?.value
        };

        updatePayments(payments?.data?._id, body);
    }

    useEffect(() => {
        if(payments?.update?.fetching === "failure"){
            toast("Ha ocurrido un error al actualizar las intrucciones de pago");
            cleanUpdatePayments();
        }
        if(payments?.update?.fetching === "success"){
            toast("Instrucciones de pago actualizadas correctamente");
            cleanUpdatePayments();
        }
    }, [payments?.update?.fetching]);

    useEffect(() => {
        if(payments?.fetching === "failure"){
            toast("Ha ocurrido un error al mostrar las intrucciones de pago");
            cleanGetPayments();
        }
        if(payments?.fetching === "success"){
            setValeVista({
                type: {
                    value: payments?.data?.type ?? "",
                    error: ""
                },
                mount: {
                    value: payments?.data?.amount ?? "",
                    error: ""
                }
            });
            setBeneficiary({
                name: {
                    value: payments?.data?.beneficiary_name ?? "",
                    error: ""
                },
                rut: {
                    value: payments?.data?.rut ?? "",
                    error: ""
                }
            });
            cleanGetPayments();
        }
    }, [payments?.fetching]);

    useEffect(() => {
        getPayments();
    }, []);

    return (
        <div className={styles.payments}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <ButtonShared
                        onPress={onSavePayments}
                        title="Guardar"
                        icon={<SaveOutlinedIcon className={styles.iconButton} />}
                        primary
                        loading={payments.update.fetching === "loading"}
                    />
                </div>
                <div className={styles.content}>
                    <div className={styles.section}>
                        <p className={styles.title}>Vale vista</p>
                        <TextFieldShared
                            label="Tipo de vale vista"
                            name="typeValeVista"
                            onChange={(value: any) => setValeVista({...valeVista, type: {...valeVista.type, value: value}})}
                            value={valeVista?.type?.value}
                            error={valeVista?.type?.error}
                            loading={payments.fetching === "loading"}
                        />
                        <TextFieldShared
                            label="Monto de vale vista"
                            name="mountValeVista"
                            onChange={(value: any) => setValeVista({...valeVista, mount: {...valeVista.mount, value: value}})}
                            value={valeVista?.mount?.value}
                            error={valeVista?.mount?.error}
                            loading={payments.fetching === "loading"}
                        />
                    </div>
                    <Divider />
                    <div className={styles.section}>
                        <p className={styles.title}>Beneficiario</p>
                        <TextFieldShared
                            label="Nombre del beneficiario"
                            name="nameBeneficiary"
                            onChange={(value: any) => setBeneficiary({...beneficiary, name: {...beneficiary.name, value: value}})}
                            value={beneficiary?.name?.value}
                            error={beneficiary?.name?.error}
                            loading={payments.fetching === "loading"}
                        />
                        <TextFieldShared
                            label="RUT del beneficiario"
                            name="rutBeneficiary"
                            onChange={handleChangeRut}
                            value={beneficiary?.rut?.value}
                            error={beneficiary?.rut?.error}
                            loading={payments.fetching === "loading"}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(Payments);
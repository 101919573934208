import { createSlice } from "@reduxjs/toolkit";
import { BranchsStateProps } from "../interfaces/branchs";

const initialState: BranchsStateProps = {
    list: {
        fetching: "unknown",
        data: [],
        total: 0,
        error: ""
    }
};

const branchsSlice = createSlice({
    name: "branchs",
    initialState,
    reducers: {
        getBranchsRequest: (state) => {
            state.list.fetching = "loading";
        },
        getBranchsSuccess: (state, action) => {
            state.list.fetching = "success";
            state.list.data = action.payload;
        },
        getBranchsFailure: (state, action) => {
            state.list.fetching = "failure";
            state.list.error = action.payload;
        },
        cleanGetBranchs: (state) => {
            state.list.fetching = initialState.list.fetching;
        }
    },
});

export const {
    getBranchsRequest,
    getBranchsSuccess,
    getBranchsFailure,
    cleanGetBranchs
} =
    branchsSlice.actions;

export default branchsSlice.reducer;

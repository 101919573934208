import { Dispatch } from "@reduxjs/toolkit";
import { api } from "../config/api";
import { GetState } from "../config/store";
import { 
    getAdditionalCostsRequest, 
    getAdditionalCostsSuccess,
    getAdditionalCostsFailure, 
    cleanGetAdditionalCosts as cleanGetAdditionalCostsReducer,
    updateAdditionalCostsRequest, 
    updateAdditionalCostsSuccess,
    updateAdditionalCostsFailure, 
    cleanUpdateAdditionalCosts as cleanUpdateAdditionalCostsReducer
} from "../reducers/additionalCosts";
import { axiosClient } from "../config/axios";

export const getAdditionalCosts = (flow: string) => (dispatch: Dispatch) => _getAdditionalCosts(flow, dispatch);
const _getAdditionalCosts = async (flow: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: getAdditionalCostsRequest });
        
        let params = "";
        if(flow === "financing"){
            params = `operation_flow=transaction&flow_type=financing&operation_type=purchase`;
        }
        if(flow === "cash"){
            params = `operation_flow=transaction&flow_type=cash&operation_type=purchase`;
        }
        if(flow === "publication"){
            params = `operation_flow=publication&operation_type=sale`;
        }

        const response = await axiosClient.get(`${api.settings}/additional-costs?&${params}`);

        dispatch({ 
            type: getAdditionalCostsSuccess, 
            payload: {
                flow: flow,
                data: response?.data.data.additional_costs
            }
        });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getAdditionalCostsFailure, payload: errorResponse });
    }
};

export const cleanGetAdditionalCosts = () => (dispatch: Dispatch) => _cleanGetAdditionalCosts(dispatch);
const _cleanGetAdditionalCosts = async (dispatch: Dispatch) => dispatch({ type: cleanGetAdditionalCostsReducer });

export const updateAdditionalCosts = (body: object) => (dispatch: Dispatch) => _updateAdditionalCosts(body, dispatch);
const _updateAdditionalCosts = async (body: object, dispatch: Dispatch) => {
    try {
        dispatch({ type: updateAdditionalCostsRequest });

        const bodyReq = JSON.stringify({ "additional_costs": body });

        const response = await axiosClient.put(`${api.settings}/additional-costs/multiple`, bodyReq);

        dispatch({ 
            type: updateAdditionalCostsSuccess, 
            payload: response?.data?.data
        });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: updateAdditionalCostsFailure, payload: errorResponse });
    }
};

export const cleanUpdateAdditionalCosts = () => (dispatch: Dispatch) => _cleanUpdateAdditionalCosts(dispatch);
const _cleanUpdateAdditionalCosts = async (dispatch: Dispatch) => dispatch({ type: cleanUpdateAdditionalCostsReducer });
import { memo, useEffect, useMemo, useState } from "react";
import styles from "./entryPoint.module.scss";
import { PublicationPageProps } from "../../interfaces/pages";
import { useToast } from "../../helpers/hooks";
import { useNavigate, useParams } from "react-router-dom";
import PublicationDetail from "../../components/PublicationDetail";
import ButtonShared from "../../components/Button/Button";
import { useWindowSize } from "use-hooks";
import { StatusPublication } from "../../interfaces/types";
import Modal from "../../components/Modal";
import OffersModal from "../../containers/Publication/OffersModal/OffersModalContainer";
import DeleteModal from "../publications/Delete";

const Publication = ({
    publication,
    getPublication,
    cleanGetPublication,
    getPublicationArticles,
    cleanGetPublicationArticles,
    putStatusPublication,
    cleanPutStatusPublication,
    deletePublication,
    getOfferAccepted,
    cleanDeletePublication,
    getPublicationsByStatus
}: PublicationPageProps) => {
    const params = useParams();
    const navigate = useNavigate();
    const toast = useToast();
    const windowSize = useWindowSize();
    const [publicationAux, setPublicationAux] = useState<any>({});
    const [statusSelected, setStatusSelected] = useState<StatusPublication>("pending");
    const [openRejectedModal, setOpenRejectedModal] = useState<boolean>(false);
    const [openOffersModal, setOpenOffersModal] = useState<boolean>(false);
    const [deleteModalState, setDeleteModalState] = useState<boolean>(false);
    const [publicationSelected, setPublicationSelected] = useState<any>("");


    const publicationNormalized: any = useMemo(() => {
        const publicationObject = {
            id: publicationAux?.data?._id,
            pictures: publicationAux?.data?.pictures,
            title: publicationAux?.data?.title,
            year: publicationAux?.data?.primary_description,
            mileage: publicationAux?.data?.secondary_description,
            price: publicationAux?.data?.price,
            liked: publicationAux?.data?.liked,
            chatgpt_description: publicationAux?.data?.chatgpt_description,
            caracteristics: {
                type: publicationAux?.articles?.data?.type,
                color: publicationAux?.articles?.data?.color,
                transmission: publicationAux?.articles?.data?.transmission,
                fuel: publicationAux?.articles?.data?.fuel
            },
            confort: publicationAux?.articles?.data?.confort && Object.entries(publicationAux?.articles?.data?.confort).map((item: any) => [item[0], item[1]]),
            tags: publicationAux?.data?.tags,
            version: (publicationAux?.articles?.data?.version_id !== 0 && publicationAux?.articles?.data?.version_id !== 1) ? publicationAux?.articles?.data?.version : null,
            certified: publicationAux?.data?.certified,
            paused: publicationAux?.data?.paused
        }
        return publicationObject;
    }, [publicationAux]);

    const onAcceptRejected = () => {
        putStatusPublication(publicationNormalized.id, "rejected");
    }

    const onChangeStatus = (status: StatusPublication) => {
        setStatusSelected(status);
        if (status === "rejected") {
            setOpenRejectedModal(true);
            return;
        }
        putStatusPublication(publicationNormalized.id, status);
    }

    const onDelete = () => {
        if (publication?.data?.in_transaction) {
            toast("No puedes eliminar una publicación en transacción");
            return
        }
        setPublicationSelected(publication?.data);
        getOfferAccepted(publication?.data?._id);
    }

    const onDeleteModal = (id: string) => {
        deletePublication(id);
    }

    useEffect(() => {
        if (publication?.put?.fetching === "failure") {
            if (statusSelected === "rejected") {
                setOpenRejectedModal(false);
            }
            cleanPutStatusPublication();
            toast("Ha ocurrido un error al actualizar la publicación");
            return;
        }
        if (publication?.put?.fetching === "success") {
            if (statusSelected === "rejected") {
                setOpenRejectedModal(false);
            }
            toast("Publicación actualizada correctamente");
            cleanPutStatusPublication();
        }
    }, [publication?.put?.fetching]);

    useEffect(() => {
        if (publication?.articles?.fetching === "failure") {
            cleanGetPublicationArticles();
            if (publication?.articles?.error === "ID_INVALID_FORMAT") {
                return navigate("/publications");
            }
            toast("Ha ocurrido un error para mostrar la publicación");
        }
        if (publication?.articles?.fetching === "success") {
            setPublicationAux(publication);
            cleanGetPublication();
        }
    }, [publication?.articles?.fetching]);

    useEffect(() => {
        if (publication?.fetching === "failure") {
            cleanGetPublication();
            if (publication?.error === "ID_INVALID_FORMAT") {
                return navigate("/publications");
            }
            toast("Ha ocurrido un error para mostrar la publicación");
        }
        if (publication?.fetching === "success") {
            setPublicationAux(publication);
            cleanGetPublication();
        }
    }, [publication?.fetching]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (params?.id) {
            getPublicationArticles(params?.id);
            getPublication(params?.id);
        } else {
            navigate("/publications");
        }
    }, [params]);

    useEffect(() => {
        if (publication.fetchingOfferAccepted === "success" && publicationSelected?._id) {
            if (!publication.offerAccepted) {
                setDeleteModalState(true);
            } else {
                toast("No puedes eliminar una publicación en transacción");
            }
        }
    }, [publication.fetchingOfferAccepted])

    useEffect(() => {
        if (publication.fetchingDeletePublication === "success") {
            toast("Publicación eliminada");
            setDeleteModalState(false);
            setTimeout(() => {
                navigate(-1)
                getPublicationsByStatus(1);
                cleanDeletePublication();
            }, 1000)
        }
    }, [publication.fetchingDeletePublication])

    return (
        <>
            <div className={styles.container}>
                <Modal open={openRejectedModal} variant="white" className={styles.rejectedModal}>
                    <p>
                        <span className={styles.message}>Estás a punto de DESHABILITAR la publicación del vehículo</span>
                        <span className={styles.name}>{publicationNormalized?.title}</span>
                    </p>
                    <div className={styles.buttons}>
                        <ButtonShared
                            onPress={() => setOpenRejectedModal(false)}
                            title="Regresar"
                            tertiary
                            size="small"
                            disabled={publication?.put?.fetching === "loading"}
                        />
                        <ButtonShared
                            onPress={onAcceptRejected}
                            title="Deshabilitar"
                            primary
                            size="small"
                            loading={publication?.put?.fetching === "loading"}
                        />
                    </div>
                </Modal>
                <PublicationDetail
                    publication={publicationNormalized}
                    loading={publication?.fetching === "loading" || publication?.articles?.fetching === "loading"}
                >
                    {
                        publication?.data?.status_brief?.code === "pending" &&
                        <>
                            <ButtonShared
                                onPress={() => onChangeStatus("published")}
                                title="Aprobar"
                                tertiary
                                className={`${styles.button} ${styles.enable}`}
                                size={(windowSize.width < 768) ? "small" : "normal"}
                                loading={publication?.put?.fetching === "loading" && statusSelected === "published"}
                            />
                            <ButtonShared
                                onPress={() => onChangeStatus("rejected")}
                                title="Rechazar"
                                primary
                                className={styles.button}
                                size={(windowSize.width < 768) ? "small" : "normal"}
                                disabled={publication?.put?.fetching === "loading" && statusSelected !== "rejected"}
                            />
                            <ButtonShared
                                onPress={onDelete}
                                title="Eliminar"
                                tertiary
                                className={`${styles.button} ${styles.enable} ${styles.delete}`}
                                size={(windowSize.width < 768) ? "small" : "normal"}
                                loading={publication?.fetchingDeletePublication === "loading" && statusSelected === "published"}
                            />
                        </>
                    }
                </PublicationDetail>
            </div>
            <OffersModal
                open={openOffersModal}
                onClose={() => setOpenOffersModal(false)}
                id={publicationNormalized?.id}
            />
            <DeleteModal
                open={deleteModalState}
                setOpen={() => setDeleteModalState(false)}
                publicationSelected={publicationSelected}
                onDelete={onDeleteModal}
            />
        </>
    )
}

export default memo(Publication);
import { tabProps } from "../interfaces/sidebar";

export const tabs: tabProps[] = [
    {
        id: "dashboard",
        label: "Dashboards",
        icon: "dashboard",
        navigate:"",
        children:[
            {
                id: "dashboard",
                label: "Dashboard Ejecutivo",
                icon: "dashboardEx",
                navigate: "dashboard"
            },
            {
                id: "dashboardit",
                label: "Dashboard Performance",
                icon: "dashboardIt",
                navigate: "dashboardIt"
            },
            {
                id: "DashboardIntegrations",
                label: "Dashboard Integraciones",
                icon: "DashboardIntegrations",
                navigate: "DashboardIntegrations"
            },
        ]
    },
    {
        id: "dealerships",
        label: "Concesionarias",
        icon: "dealerships",
        navigate: "dealerships"
    },
    {
        id: "users",
        label: "Usuarios",
        icon: "users",
        navigate: "users"
    },
    {
        id: "leads",
        label: "Leads",
        icon: "leads",
        navigate: "leads"
    },
    {
        id: "publications",
        label: "Publicaciones",
        icon: "publications",
        navigate: "publications",
        children: [
            {
                id: "pendings",
                label: "Publicaciones en revisión",
                icon: "review",
                navigate: "pendings",
                type: "param"
            },
            {
                id: "alls",
                label: "Todas las publicaciones",
                icon: "allPublications",
                navigate: "all",
                type: "param"
            },
        ]
    },
    {
        id: "settings",
        label: "Configuración",
        icon: "settings",
        navigate: "settings",
        children: [
            {
                id: "terms",
                label: "Terminos y condiciones",
                icon: "terms",
                navigate: "terms"
            },
            {
                id: "additionalCosts",
                label: "Costos adicionales",
                icon: "additionalCosts",
                navigate: "additionalCosts"
            },
            {
                id: "payments",
                label: "Instrucciones de pago",
                icon: "payments",
                navigate: "payments"
            },
            {
                id: "pickupPoints",
                label: "Puntos de inspección",
                icon: "pickupPoints",
                navigate: "pickupPoints"
            }
        ]
    }
];
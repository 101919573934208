import styles from './confortsLoader.module.scss';
import { Skeleton } from "@mui/material";
import Confort from '../../../Confort';

const ConfortsLoader = () => {

    return (
        <div className={styles.skeletonContainer}>
            <Skeleton animation="wave" variant="text" className={styles.title} />
            <div className={styles.content}>
                <Confort loading />
                <Confort loading />
                <Confort loading />
                <Confort loading />
            </div>
        </div>
    )
}

export default ConfortsLoader;
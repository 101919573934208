import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import OffersModal from "../../../pages/publication/OffersModal";
import { PublicationContainerProps } from "../../../interfaces/containers";
import { getOffers, cleanGetOffers } from "../../../actions/publication";

function mapStateToProps(state: PublicationContainerProps) {
    return{
        offers: state.publication.offers
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getOffers, 
        cleanGetOffers
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(OffersModal);
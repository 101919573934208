import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import EntryPoint from "../../../pages/settings/payments";
import { PaymentsContainerProps } from "../../../interfaces/containers";
import { 
    getPayments, 
    cleanGetPayments, 
    updatePayments, 
    cleanUpdatePayments
} from "../../../actions/payments";

function mapStateToProps(state: PaymentsContainerProps) {
    return{
        payments: state.payments
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getPayments, 
        cleanGetPayments, 
        updatePayments, 
        cleanUpdatePayments
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(EntryPoint);
import { Dispatch } from "@reduxjs/toolkit";
import { api } from "../config/api";
import { GetState } from "../config/store";
import { 
    getPublicationsByStatusRequest,
    getPublicationsByStatusSuccess,
    getPublicationsByStatusFailure,
    cleanGetPublicationsByStatus as cleanGetPublicationsByStatusReducer,
    getSellersRequest,
    getSellersSuccess,
    getSellersFailure,
    cleanGetSellers as cleanGetSellersReducer,
    getStatusRequest,
    getStatusSuccess,
    getStatusFailure,
    cleanGetStatus as cleanGetStatusReducer,
    setSearch as setSearchReducer,
    setStatus as setStatusReducer,
    setYears as setYearsReducer,
    setSellers as setSellersReducer,
    getReportsRequest,
    getReportsSuccess,
    getReportsFailure
} from "../reducers/publications";
import { axiosClient } from "../config/axios";

export const setSearch = (status: any) => (dispatch: Dispatch) => _setSearch(status, dispatch);
const _setSearch = (status: any, dispatch: Dispatch) => dispatch({type: setSearchReducer, payload: status});

export const setStatus = (status: any) => (dispatch: Dispatch) => _setStatus(status, dispatch);
const _setStatus = (status: any, dispatch: Dispatch) => dispatch({type: setStatusReducer, payload: status});

export const setYears = (status: any) => (dispatch: Dispatch) => _setYears(status, dispatch);
const _setYears = (status: any, dispatch: Dispatch) => dispatch({type: setYearsReducer, payload: status});

export const setSellers = (status: any) => (dispatch: Dispatch) => _setSellers(status, dispatch);
const _setSellers = (status: any, dispatch: Dispatch) => dispatch({type: setSellersReducer, payload: status});

export const getPublicationsByStatus = (page?: number, limit?: number) => (dispatch: Dispatch, getState: GetState) => _getPublicationsByStatus(page, limit, dispatch, getState);
const _getPublicationsByStatus = async (page = 1, limit = 10, dispatch: Dispatch, getState: GetState) => {
    try {
        dispatch({ type: getPublicationsByStatusRequest });
        const filters = getState().publications.filters;

        let url = "";
        let status = "";
        let years = "";
        
        if (filters?.status && filters?.status.length > 0) {
            filters.status.forEach((state: any) => {
                status = status.concat(state?.id + ",")
            })
            url = status ? `status=${status.slice(0, -1)}` : "";
        }

        if (filters?.years && filters?.years.length > 0) {
            filters.years.forEach((year: any) => {
                years = years.concat(year?.name + ",")
            })
            url = url + `${url ? '&' : ''}article.year=1,${years.slice(0, -1)}`;
        }

        if (filters?.text && filters?.text.length > 0) {
            url = url + `${url ? '&' : ''}text=${filters?.text}`;
        }

        if (url) {
            url = "&" + url;
        }

        const response = await axiosClient.get(`${api.publications}/protected?all=true&page=${page}&limit=${limit}${url}`);

        dispatch({ 
            type: getPublicationsByStatusSuccess, 
            payload: {
                publications: response?.data.data.publications,
                total: response?.data.data.total,
                found: response?.data.data.found
            }
        });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getPublicationsByStatusFailure, payload: errorResponse });
    }
};

export const cleanGetPublicationsByStatus = () => (dispatch: Dispatch) => _cleanGetPublicationsByStatus(dispatch);
const _cleanGetPublicationsByStatus = async (dispatch: Dispatch) => dispatch({type: cleanGetPublicationsByStatusReducer});

export const getSellers = (id: string) => (dispatch: Dispatch) => _getSellers(id, dispatch)
const _getSellers = async (id: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: getSellersRequest });

        const response = await axiosClient.get(`${api.users}/by-dealership/${id}`);

        dispatch({ type: getSellersSuccess, payload: response?.data?.data?.users});
        
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getSellersFailure, payload: errorResponse });
  }
};

export const cleanGetSellers = () => (dispatch: Dispatch) => _cleanGetSellers(dispatch);
const _cleanGetSellers = async (dispatch: Dispatch) => dispatch({type: cleanGetSellersReducer});

export const getStatus = () => (dispatch: Dispatch) => _getStatus(dispatch);
const _getStatus = async (dispatch: Dispatch) => {
    try {
        dispatch({ type: getStatusRequest });

        const response = await axiosClient.get(`${api.publications}/status`);
        

        dispatch({ type: getStatusSuccess, payload: response?.data?.data?.statuses });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getStatusFailure });
    }
}


export const getReports = () => (dispatch: Dispatch) => _getReports(dispatch);
const _getReports = async (dispatch: Dispatch) => {
    try {
        dispatch({ type: getReportsRequest });

        const response = await axiosClient.post(`${api.reports}/publications/reports`, {}, {
            responseType: 'blob'
        });

        // Create blob and download
        const blob = new Blob([response.data], { 
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        
        // Format current date
        const now = new Date();
        const formattedDate = now.toISOString().split('T')[0]; // Format: YYYY-MM-DD
        
        link.setAttribute('download', `publications-report-${formattedDate}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);

        dispatch({ type: getReportsSuccess });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getReportsFailure });
    }
}

export const cleanGetStatus = () => (dispatch: Dispatch) => _cleanGetStatus(dispatch);
const _cleanGetStatus = (dispatch: Dispatch) => dispatch({type: cleanGetStatusReducer});
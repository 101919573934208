import { RouteObject } from "../../interfaces/navigation";
import EntryPoint from "../../containers/Leads/EntryPointContainer";
import Publication from "../../containers/Publication/EntryPointContainer";

export const leadsSubRoutes: RouteObject[] = [
    {
        title: "Gestión",
        path: ":id/management",
        component: <Publication/>
    }
];

export const leadsRoutes: RouteObject[] = [
    {
        title: "Mis Leads",
        path: "leads",
        component: <EntryPoint/>,
        subRoutes: leadsSubRoutes
    }
];
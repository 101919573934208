import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import EntryPoint from "../../../pages/settings/additionalCosts";
import { AdditionalCostsContainerProps } from "../../../interfaces/containers";
import { 
    getAdditionalCosts, 
    cleanGetAdditionalCosts, 
    updateAdditionalCosts,
    cleanUpdateAdditionalCosts
} from "../../../actions/additionalCosts";

function mapStateToProps(state: AdditionalCostsContainerProps) {
    return{
        additionalCosts: state.additionalCosts
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getAdditionalCosts, 
        cleanGetAdditionalCosts, 
        updateAdditionalCosts,
        cleanUpdateAdditionalCosts
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(EntryPoint);
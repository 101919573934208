import { memo, useEffect, useState } from "react";
import styles from "./entryPoint.module.scss";
import PageCard from "../../../components/PageCard/PageCard";
import { TermsPageProps } from "../../../interfaces/pages";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Loader from "../../../components/Loader";
import { useToast } from "../../../helpers/hooks";
import ButtonShared from "../../../components/Button/Button";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

const Terms = ({
    terms,
    getTerms,
    cleanGetTerms,
    updateTerms,
    cleanUpdateTerms
}: TermsPageProps) => {
    const toast = useToast();
    const [editorState, setEditorState] = useState<any>();

    const onSaveTerms = () => {
        const file = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        updateTerms(file);
    }

    useEffect(() => {
        if(terms?.data?.content){
            const contentBlock = htmlToDraft(terms?.data?.content);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editor = EditorState.createWithContent(contentState);
                setEditorState(editor);
            }
        }
    },[terms?.data]);

    useEffect(() => {
        if(terms.update.fetching === "failure"){
            toast("Ha ocurrido un error al actualizar los terminos y condiciones");
            cleanUpdateTerms();
        }
        if(terms.update.fetching === "success"){
            toast("Terminos y condiciones actualizados correctamente");
            cleanUpdateTerms();
        }
    }, [terms.update.fetching]);

    useEffect(() => {
        if(terms.fetching === "failure"){
            toast("Ha ocurrido un error al mostrar los terminos y condiciones");
            cleanGetTerms();
        }
        if(terms.fetching === "success"){
            cleanGetTerms();
        }
    }, [terms.fetching]);

    useEffect(() => {
        getTerms();
    }, []);

    return (
        <PageCard>
            <div className={styles.container}>
                {
                    terms.fetching === "loading"
                    ?
                        <Loader text="Cargando terminos y condiciones" className={styles.loader} />
                    :
                        <div className={styles.content}>
                            <div className={styles.header}>
                                <ButtonShared
                                    onPress={onSaveTerms}
                                    title="Guardar"
                                    icon={<SaveOutlinedIcon className={styles.iconButton} />}
                                    primary
                                    loading={terms.update.fetching === "loading"}
                                />
                            </div>
                            <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={(e) => setEditorState(e)}
                                wrapperStyle={{
                                    width: "100%"
                                }}
                                editorStyle={{ 
                                    margin: '16px 0',
                                    fontSize: 14,
                                    backgroundColor: 'rgb(244, 244, 244)',
                                    height: "auto"
                                }}
                                toolbar={{
                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign'],
                                }}
                                toolbarStyle= {{
                                    backgroundColor: '#ececec', 
                                    border: 'none',
                                    padding: "8px 0 2px",
                                    margin: "0",
                                    marginBottom: 0
                                }}
                            />
                        </div>
                }
            </div>
        </PageCard>
    )
}

export default memo(Terms);
import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { UsersContainerProps } from "../../../interfaces/containers";
import Disabled from "../../../pages/Users/Disabled";
import { disabledUser, cleanDisabledUser } from "../../../actions/users";

function mapStateToProps(state: UsersContainerProps) {
    return {
        users: state.users
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        disabledUser, 
        cleanDisabledUser
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Disabled);
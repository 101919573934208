import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import EntryPoint from "../../../pages/settings/terms";
import { TermsContainerProps } from "../../../interfaces/containers";
import { getTerms, cleanGetTerms, updateTerms, cleanUpdateTerms } from "../../../actions/terms";

function mapStateToProps(state: TermsContainerProps) {
    return{
        terms: state.terms
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        getTerms,
        cleanGetTerms,
        updateTerms,
        cleanUpdateTerms
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(EntryPoint);
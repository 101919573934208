export const images = {
    logotipo: require("../assets/images/logo/logotipo.svg").default,
    logoIsotipo: require("../assets/images/logo/isotipo.svg").default,
    notFoundPage: require("../assets/images/notFoundPage.png"),
    loginBackground: require("../assets/images/loginBackground.jpg"),
    profile: require("../assets/images/profile.png"),
    noData: require("../assets/images/noData.png"),
    noImagePublication: require("../assets/images/noImagePublication.jpg"),
    airFilter: require("../assets/images/conforts/airFilter.png"),
    cruiceSpeed: require("../assets/images/conforts/cruiceSpeed.png"),
    electricCrystals: require("../assets/images/conforts/electricCrystals.png"),
    parkingSensor: require("../assets/images/conforts/parkingSensor.png"),
    onBoardComputer: require("../assets/images/conforts/onBoardComputer.png"),
    audioHifi: require("../assets/images/conforts/audioHifi.png"),
    bluetoothWifi: require("../assets/images/conforts/bluetoothWifi.png"),
    leatherSeats: require("../assets/images/conforts/leatherSeats.png"),
    carType: require("../assets/images/caracteristics/carType.png"),
    color: require("../assets/images/caracteristics/color.png"),
    transmission: require("../assets/images/caracteristics/transmission.png"),
    fuel: require("../assets/images/caracteristics/fuel.png"),
    close: require("../assets/images/close.png"),
    linzeWhite: require("../assets/images/linze_white_chat.png"),
    account: require("../assets/images/account.png")
};